import React from "react"
import { useSelector } from "react-redux"
import { string } from "prop-types"

function TrackMyOrderButton({ className, awbNumber }) {
  const { isTrackingAvailable, trackingBaseUrl } = useSelector(state => ({
    isTrackingAvailable:
      state.SiteSettings.siteSettings.shipping.isTrackingAvailable,
    trackingBaseUrl: state.SiteSettings.siteSettings.shipping.trackingBaseUrl,
  }))

  return (
    <>
      {isTrackingAvailable && trackingBaseUrl && (
        <a
          href={`${trackingBaseUrl}${awbNumber}`}
          target="_blank"
          rel="noopener noreferrer"
          id="invoice_download_btn"
          className={className}
          style={{
            padding: "7px 13px",
            fontSize: "12px",
            whiteSpace: "nowrap",
          }}
        >
          Track Order
        </a>
      )}
    </>
  )
}

export default TrackMyOrderButton

TrackMyOrderButton.propTypes = {
  className: string,
  awbNumber: string,
}
