import { useDispatch, useSelector } from "react-redux"
import Layout from "components/layout/Layout"
import React, { useEffect, useState } from "react"
import { useWindowSize } from "react-use"
import WishlistItemWeb from "components/elements/WishlistItemWeb"
import CartItemMob from "components/elements/CartItemMob"
import { getFavouriteItems, getProducts } from "store/actions"
import { map } from "lodash"
import ProductCardOne from "components/ecommerce/productCards/ProductCardOne"
import HeadBodyGrid from "components/Common/loaders/gridLoad"
import ProductLoader from "components/Common/loaders/ProductLoader"
import InfiniteScroll from "react-infinite-scroll-component"
import { Link } from "react-router-dom"

const Wishlist = () => {
  const dispatch = useDispatch()

  const { favourites, loading, products, prodLoading } = useSelector(state => ({
    favourites: state.Cart.favourites,
    loading: state.Cart.favouriteLoad,
    products: state.Products.products,
    prodLoading: state.Products.loading,
  }))

  const { width } = useWindowSize()

  const totalList = favourites?.total

  //pagination
  const limit = 10
  const allpages = totalList / limit
  let [pages, setPages] = useState(1)

  const HandlefetchNextPage = () => {
    if (pages < allpages) {
      setPages(pages + 1)
    }
  }

  useEffect(() => {
    dispatch(getFavouriteItems(pages, limit))
  }, [dispatch, pages, limit])

  useEffect(() => {
    const options = {
      searchText: "",
      page: 1,
      custom: "all",
      sort: "",
      limit: 4,
    }

    dispatch(getProducts(options))
  }, [dispatch])

  return (
    <>
      <Layout parent="Home" sub="Shop" subChild="wish list">
        <section className="mt-50 mb-50 p-30 pt-0 sm:mt-0 sm:p-0 myCart">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 mb-40">
                <h1 className="heading-2 mb-10">Wish List</h1>
                {favourites?.total > 0 && (
                  <div className="d-flex justify-content-between sm:flex-col">
                    <p className="text-body subHead mb-4">
                      There are
                      <span className="text-brand">
                        &nbsp;{favourites?.total}
                      </span>{" "}
                      products in your wish list
                    </p>
                  </div>
                )}
              </div>
            </div>
            <InfiniteScroll
              dataLength={favourites?.favourites?.length ?? 0}
              next={HandlefetchNextPage}
              hasMore={true}
              loader={loading && <ProductLoader height="200px" />}
            >
              <div className="row">
                <div className="col-lg-8 ">
                  {favourites?.total > 0 ? (
                    <>
                      {width > 480 ? (
                        <WishlistItemWeb
                          wishlist={favourites?.favourites}
                          isQuickList={false}
                        />
                      ) : (
                        map(favourites?.favourites, (item, key) => (
                          <CartItemMob item={item} key={key} isWish={true} />
                        ))
                      )}
                    </>
                  ) : (
                    <div className="pb-30 mb-10 border-b-4">
                      <p className="">
                        No wish list items,
                        <br />
                        <span>
                          “Happiness is not in money, but in shopping.”
                        </span>
                      </p>
                      <Link to="/product" className="m-auto w-fit">
                        <a className="btn btn-xs submit-auto-width font-smaller hover-up mt-2">
                          Back To Shopping
                        </a>
                      </Link>
                    </div>
                  )}
                </div>

                <div className="col-lg-4 ml-auto d-flex flex-wrap">
                  {prodLoading ? (
                    <HeadBodyGrid />
                  ) : (
                    <>
                      <h3 className="mb-3 w-100 text-gray font-mainHead">
                        You may like
                      </h3>
                      <div className="d-flex flex-wrap">
                        {map(products?.products, (product, key) => (
                          <div
                            className="mb-md-0  sm:mx-0.5 sm:max-w:160px  moreProdu wow animate__animated animate__fadeInUp"
                            data-wow-delay="0"
                            key={key}
                          >
                            <ProductCardOne product={product} />
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </InfiniteScroll>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default Wishlist
