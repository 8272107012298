const useCloudinary = (imgUrl, styles, fileType) => {
  const imgUrlArray = imgUrl?.split("upload/")
  const changeExtension = imgUrl?.split(".").pop()

  const modifiedUrl =
    imgUrlArray?.length >= 1 &&
    imgUrlArray[0] + "upload/" + styles + "/" + imgUrlArray[1]

  if (fileType && modifiedUrl?.length >= 1) {
    return modifiedUrl?.split(changeExtension)[0] + fileType
  }
  return modifiedUrl
}

export default useCloudinary
