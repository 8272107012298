import { useLocation } from "react-router-dom"
import { useArrayToString } from "./useArrayToString"
import { useHtmlToString } from "./useHtmlToString"
import useCloudinary from "./useCloudinaryImage"

const useMetaHandler = (title, description, product) => {
  const location = useLocation()
  const isHtml = /<\/?[a-z][\s\S]*>/i.test(description)
  const isArray = Array.isArray(product?.keywords)

  return {
    canonicalLink: location.pathname,
    metaTitle: title && title?.slice(0, 55),
    metaDescription: isHtml
      ? useHtmlToString(description)?.slice(0, 170)
      : description?.slice(0, 170),
    metaKeyword: isArray
      ? useArrayToString(product?.keywords)
      : product?.keywords,
    metaProduct: {
      amount: product?.offerPrice ? product?.offerPrice : product?.price,
      availability: product?.stock > 0 ? "in stock" : "out of stock",
      itemCode: product?.itemCode,
      category: product?.categories ? product?.categories[0]?.name : "",
    },
    image: useCloudinary(product?.images[0]?.url, "w_300,h_300", "png"),
  }
}
export default useMetaHandler
