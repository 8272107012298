import React, { useEffect } from "react"

import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { useDispatch, useSelector } from "react-redux"
import Checkout from "./checkout"
import { createStripeOrder } from "store/actions"
import ProductLoader from "components/Common/loaders/ProductLoader"

const stripePromise = loadStripe(
  "pk_live_51McRc2SBrIuU8W3yKoRw38OIKQkNBBLFOGFOeCZEKhdCdvLUYfJEyo8xWV2jTGWZ9T2HvuujCvMgNKbmuYf4P8BB00tz3hAJpA"
)

function index() {
  const dispatch = useDispatch()
  const { stripeInfo } = useSelector(state => ({
    stripeInfo: state.Cart.stripeInfo,
  }))

  const { paymentKey } = useSelector(state => ({
    paymentKey: state.SiteSettings.paymentKey,
  }))

  useEffect(() => {
    if (paymentKey?.paymentGateway === "Stripe") {
      dispatch(createStripeOrder())
    }
  }, [paymentKey?.paymentGateway])

  if (paymentKey?.paymentGateway === "RazorPay") {
    return <Checkout />
  } else if (stripeInfo?.clientSecret?.length >= 1) {
    const options = {
      clientSecret: stripeInfo?.clientSecret,
    }
    return (
      <Elements stripe={stripePromise} options={options}>
        <Checkout />
      </Elements>
    )
  } else return <Checkout />
}

export default index
