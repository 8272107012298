import Layout from "components/layout/Layout"
import React, { useEffect, useState } from "react"
import UserAddressList from "./userAddressList"
import { useDispatch, useSelector } from "react-redux"
import {
  couponValidate,
  createRazorpayOrder,
  createStripeOrder,
  getLocalCart,
  getOpenPaymentDetails,
  getPaymentKey,
  getSiteSettings,
  getUserAddress,
  getUserCart,
  makeUserOrder,
  paymentClearOrderState,
  showToasterAction,
  updateUserOrder,
  verifyRazorpayOrder,
} from "store/actions"
import usePrice from "components/hooks/usePrice"
import { Link, useHistory } from "react-router-dom"
import SmallCardLoader from "components/Common/loaders/SmallCardLoader"

import useRazorpay from "react-razorpay"
import { FaLock, FaSpinner } from "react-icons/fa"
import CheckoutItem from "./checkoutItem"
import { useWindowSize } from "react-use"
import ProductLoader from "components/Common/loaders/ProductLoader"
import CodConfirmPopup from "./CodConfirmPopup"
import OrderItemPopup from "./OrderItemPopup"

import {
  googlepay,
  mastercard,
  paytm,
  rupay,
  UPI,
  visa,
} from "assets/imgs/payment"
import {
  CardElement,
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js"
import { Button, UncontrolledTooltip } from "reactstrap"

const Checkout = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const Razorpay = useRazorpay()
  const { width } = useWindowSize()
  // const stripe = useStripe()
  // const elements = useElements()
  const { currencyCode } = usePrice()
  const token = localStorage.getItem("idealeshope_token")

  const {
    userCart,
    loading,
    cartItems,
    paymentType,
    paymentKey,
    coupon,
    couponLoad,
    orderLoad,
    paymentInfo,
    paymentLoad,
    paymentVerifyInfo,
    verifiedOrder,
    localCart,
    shipping,
    paymentGateway,
    stripeInfo,
    order,
    openPaymentInfo,
    error,
    otherPaymentOption,
  } = useSelector(state => ({
    userCart: state.Cart.userCart,
    loading: state.Cart.loading,
    cartItems: state.Cart.cartItems,
    localCart: state.Cart.localCart,
    coupon: state.Cart.coupon,
    couponLoad: state.Cart.couponLoad,
    otherPaymentOption: state.SiteSettings.otherPaymentOptions,
    paymentType: state.SiteSettings.siteSettings.paymentType,
    paymentKey: state.SiteSettings.paymentKey,
    orderLoad: state.Cart.orderLoad,
    paymentInfo: state.Cart.paymentInfo,
    openPaymentInfo: state.Cart.openPaymentInfo,
    paymentLoad: state.Cart.paymentLoad,
    paymentVerifyInfo: state.Cart.paymentVerifyInfo,
    verifiedOrder: state.Cart.verifiedOrder,
    shipping: state.SiteSettings.siteSettings.shipping,
    paymentGateway: state.SiteSettings.paymentKey.paymentGateway,
    stripeInfo: state.Cart.stripeInfo,
    order: state.Cart.order,
    error: state.Cart.error,
  }))

  console.log("title =>")
  console.log("data :", paymentKey)

  const [changePaymentType, setChangePaymentType] = useState("Online Payment")
  const [couponText, setCouponText] = useState("")
  const [shippingAddress, setShippingAddress] = useState(null)

  const [customLoader, setCustomLoader] = useState(false)
  const [isOpen, setIsOpen] = useState({ status: false, id: "" })

  const defaultShippingCharge = shipping?.defaultShippingCharge

  console.log("changePaymentType", changePaymentType)

  useEffect(() => {
    dispatch(getUserCart())
    dispatch(getPaymentKey())
    dispatch(getUserAddress())
    dispatch(getSiteSettings())
  }, [dispatch])

  const noStock = cartItems?.length <= 0 ? true : false

  const handlePaymentInput = () => {
    if (paymentType === "Cash on Delivery") {
      return "cod"
    } else if (paymentType === "Online Payment") {
      return "online"
    } else {
      return "both"
    }
  }

  const handlePaymentChange = value => {
    setChangePaymentType(value)
  }

  ///////// PAYMENT HANDLER START ///////
  //step 1 (initiate payment method)
  const initiateCheckout = type => {
    if (shippingAddress === null || !shippingAddress) {
      return dispatch(
        showToasterAction({
          data: {
            title: "Shipping Address",
            message: "Select a Address First",
          },
          type: "default",
        })
      )
    } else {
      switch (type) {
        case "cod":
          setIsOpen({ status: true })
          break
      }

      switch (type) {
        case "online":
          handleOnlinePayment()
          break

        case "cod":
          setIsOpen({ status: true })

          // case "cod":
          // handleCODPayment()
          // console.log("data :", "cod clicked")
          // setIsOpen({ status: true });
          break
      }
    }
  }

  ////////CALL COD PAYMENT AND HANDLE ////////
  const handleCODPayment = () => {
    const orderData = {
      shippingInfo: shippingAddress,
      paymentType: changePaymentType,
    }

    if (shippingAddress === null || !shippingAddress) {
      dispatch(
        showToasterAction({
          data: {
            title: "Shipping Address",
            message: "Select a Address First",
          },
          type: "default",
        })
      )
    } else {
      dispatch(makeUserOrder(orderData, history))
      console.log("orderData=> ", orderData)
    }
  }

  ////////// CALL ONLINE PAYMENT AND HANDLE //////////
  const handleOnlinePayment = () => {
    if (shippingAddress) {
      const input = {
        shippingInfo: shippingAddress,
        paymentType: changePaymentType,
      }
      dispatch(makeUserOrder(input))
    }
  }

  //////////// OPEN PAYMENT START /////////////////
  // step 2 (call Open Payments order with previous order id)
  useEffect(() => {
    if (
      paymentType === "Online Payment" &&
      order?._id &&
      changePaymentType === "Online Payment" &&
      paymentGateway === "Open Payments"
    ) {
      OpenPaymentsInitiate()
    }
  }, [order?._id])

  const OpenPaymentsInitiate = () => {
    if (order?._id) {
      dispatch(getOpenPaymentDetails(order?._id))
    }
  }

  useEffect(() => {
    if (openPaymentInfo?.id) {
      initiateOpenPayment()
    }
  }, [openPaymentInfo?.id])

  const initiateOpenPayment = () => {
    Layer.checkout(
      {
        token: openPaymentInfo?.id,
        accesskey: paymentKey?.apiKey, //TODO update from settings
      },
      function (response) {
        // console.log("response", response)
        // button: false
        // payment_id:"sb_py_BV7lWzJ1dvbXhnX"
        // payment_token_id:"sb_pt_IYcg5oEEPoc3lS"
        // status:"captured"
        if (response && response.payment_id) {
          const input = {
            orderId: order?._id,
            onlinePayment: {
              openPayment: {
                payment_id: response?.payment_id,
                payment_token_id: response?.payment_token_id,
                status: response?.status,
              },
            },
          }
          dispatch(updateUserOrder(input, history))
        }
      },
      function (err) {
        alert(err.message)
      }
    )
  }

  //////////// OPEN PAYMENT END /////////////////

  //////////// STRIPE START /////////////////
  //step 2 (call stripe order with pervious order id)
  useEffect(() => {
    if (
      order?._id &&
      changePaymentType === "Online Payment" &&
      paymentGateway === "Stripe"
    ) {
      // stripeInitiatePayment()
    }
  }, [order?._id])

  const stripeInitiatePayment = async () => {
    const input = {
      shippingInfo: shippingAddress,
      paymentType: changePaymentType,
    }
    dispatch(makeUserOrder(input))

    if (order?._id) {
      if (!stripe || !elements) {
        return dispatch(
          showToasterAction({
            data: { message: "payment verification failed, try again" },
            type: "error",
          })
        )
      }

      const result = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: "https://idealeshope.com/order-success",
        },
      })

      console.log("data :", result)

      if (result.error) {
        console.log(result?.error?.message)
        dispatch(
          showToasterAction({
            data: { message: "payment verification failed, try again" },
            type: "error",
          })
        )
      } else {
        const input = {
          shippingInfo: shippingAddress,
          paymentType: changePaymentType,
          // onlinePayment: {
          //   isVerified: paymentVerifyInfo?.isVerified,
          //   ...paymentVerifyInfo?.razorpayRes,
          // },
        }
        // dispatch(makeUserOrder(input, history))
        console.log(input)
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }
    }
  }

  //////////// STRIPE END /////////////////

  //////////// RAZORPAY START ////////////////////
  //step 2 (call razor pay order with previously created order id)
  useEffect(() => {
    // console.log("verifiedOrder", verifiedOrder)
    // console.log("paymentGateway", paymentGateway)
    // console.log("changePaymentType", changePaymentType)
    // console.log("paymentType", paymentType)
    if (
      // paymentType === "Online Payment" && //commented this line due to error -> to handleRazorPayPayment
      verifiedOrder?._id &&
      changePaymentType === "Online Payment" &&
      paymentGateway === "RazorPay"
    ) {
      handleRazorPayPayment()
    }
  }, [verifiedOrder?._id])

  const handleRazorPayPayment = async () => {
    console.log("started")
    if (shippingAddress === null || !shippingAddress) {
      dispatch(
        showToasterAction({
          data: {
            title: "Shipping Address",
            message: "Select a Address First",
          },
          type: "default",
        })
      )
      setCustomLoader(false)
    } else {
      try {
        dispatch(createRazorpayOrder(verifiedOrder?._id))
      } catch (error) {
        console.log(error)
        dispatch(
          showToasterAction({
            data: { message: "payment verification failed, try again" },
            type: "error",
          })
        )
      }
    }
  }

  // step 3 (front end payment gateway initiate for razor pay)
  useEffect(() => {
    if (paymentInfo?.id && changePaymentType === "Online Payment") {
      initiateRazorpayPayment()
    }
  }, [paymentInfo?.id])

  const initiateRazorpayPayment = () => {
    const options = {
      key: paymentKey?.keyId,
      amount: paymentInfo?.amount,
      currency: paymentInfo?.currency,
      order_id: paymentInfo?.id,

      prefill: {
        name: shippingAddress?.name,
        email: "ecom.idealeshope@gmail.com",
        contact: shippingAddress?.contactNumber,
      },
      readonly: {
        email: true,
        contact: true,
      },
      handler: async response => {
        try {
          handleResponseStatus(response)
        } catch (error) {
          console.log(error)
          dispatch(
            showToasterAction({
              data: { message: "payment verification failed, try again" },
              type: "error",
            })
          )
        }
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    }

    const razor_pay = new Razorpay(options)
    razor_pay.open()
  }

  const handleResponseStatus = response => {
    dispatch(verifyRazorpayOrder(response))

    switch (response?.status) {
      case "success":
        // dispatch(verifyRazorpayOrder(response))
        break
      case "failure":
      case "pending":
      // dispatch(clearServerCart())
      // history.push({
      //   pathname: "/online-order-fail",
      //   state: {
      //     ...response,
      //     item: cartItems,
      //   },
      // })
      default:
        break
    }
  }

  //////////// RAZORPAY END ////////////////////

  //step 4 (update order data with payment info to back-end)
  useEffect(() => {
    if (paymentVerifyInfo?.isVerified) {
      const input = {
        orderId: verifiedOrder?._id,
        onlinePayment: {
          isVerified: paymentVerifyInfo?.isVerified,
          ...paymentVerifyInfo?.razorpayRes,
        },
      }
      dispatch(updateUserOrder(input, history))
      setTimeout(() => {
        setCustomLoader(false)
      }, 1000)
    }
  }, [paymentVerifyInfo?.isVerified])

  //end of payment
  //////// end of payment ///////////////////

  const handleSubmitCoupon = e => {
    e.preventDefault()
    dispatch(couponValidate(couponText))
  }

  const handleCartItem = () => {
    if (cartItems?.length >= 1) {
      return cartItems
    } else {
      return localCart?.filter(i => i.quantity !== 0)
    }
  }

  useEffect(() => {
    if (!token) {
      dispatch(getLocalCart())
    }
  }, [])

  useEffect(() => {
    history.listen(action => {
      if (action !== "PUSH") {
        history.go(1)
      }
    })
  }, [history])

  useEffect(() => {
    dispatch(paymentClearOrderState())
  }, [changePaymentType])

  useEffect(() => {
    if (paymentType === "Both") {
      setChangePaymentType("Online Payment")
    } else {
      setChangePaymentType(paymentType)
    }
  }, [paymentType])

  // const handleOnlinePayment = () => {
  //   if (paymentGateway === "Stripe") {
  //     return stripeInitatePayment()
  //   } else return handleRazorPayPayment()
  // }
  //razorpay

  // useEffect(() => {
  //   if (paymentInfo?.id) {
  //     initatePayment()
  //   }
  // }, [paymentInfo?.id])

  // useEffect(() => {
  //   if (paymentVerifyInfo?.isVerified) {
  //     const input = {
  //       shippingInfo: shippingAddress,
  //       paymentType: changePaymentType,
  //       onlinePayment: {
  //         isVerified: paymentVerifyInfo?.isVerified,
  //         ...paymentVerifyInfo?.razorpayRes,
  //       },
  //     }
  //     console.log(input)
  //     dispatch(makeUserOrder(input, history))
  //     setTimeout(() => {
  //       setCustomLoader(false)
  //     }, 1000)
  //   }
  // }, [paymentVerifyInfo?.isVerified])

  //stripe
  // useEffect(() => {
  //   if (changePaymentType === "Online Payment") {
  //     setCustomLoader(true)
  //     dispatch(createStripeOrder())
  //     if(stripeInfo?.clientSecret){
  //       setCustomLoader(false)
  //     }
  //   }
  // }, [changePaymentType === "Online Payment"])

  // useEffect(() => {
  //   dispatch(createStripeOrder())
  // }, [changePaymentType])

  // const handleStripePayment = () => {
  //   dispatch(createStripeOrder())
  // }

  // useEffect(() => {
  //   if (stripeInfo?.clientSecret) {
  //     stripeInitatePayment()
  //   }
  // }, [stripeInfo?.clientSecret])

  // console.log("data :", stripeInfo?.clientSecret)

  // const stripeInitatePayment = async () => {
  //   const input = {
  //     shippingInfo: shippingAddress,
  //     paymentType: changePaymentType,
  //   }
  //
  // dispatch(makeUserOrder(input, history))
  // }

  const [orderItem, setOrderItem] = useState({ status: false, id: "" })

  const handleConfirmEvent = () => {
    setIsOpen({ ...isOpen, status: false })
    handleCODPayment()
  }

  useEffect(() => {
    if (error?.length > 0 && error?.split(" ")[0] === "Remove") {
      setOrderItem({
        status: true,
      })
    }
  }, [error])

  return (
    <>
      {isOpen?.status && (
        <CodConfirmPopup
          show={isOpen?.status}
          onCloseClick={() => setIsOpen({ ...isOpen, status: false })}
          onConfirmClick={handleConfirmEvent}
          loading={orderLoad}
          data={{
            handleCartItem: handleCartItem(),
            userCart: userCart,
            defaultShippingCharge: defaultShippingCharge,
            shipping: shipping,
            changePaymentType: changePaymentType,
            shippingAddress: shippingAddress,
          }}
        />
      )}

      <OrderItemPopup
        show={orderItem?.status}
        onCloseClick={() => setOrderItem({ ...orderItem, status: false })}
        onConfirmClick={handleConfirmEvent}
        // loading={orderLoad}
        data={{
          handleCartItem: handleCartItem(),
          error: error,
        }}
      />
      <Layout parent="Home" sub="Shop" subChild="Checkout">
        <section className="mt-50 mb-50 myCheckout">
          <div className="container px-5 ">
            <div className="row">
              <div className="col-lg-8">
                <div className="row mb-50">
                  <div className="mb-sm-15 mb-lg-0 mb-md-3 ">
                    <div
                      className="panel-collapse login_form checkout_mob"
                      id="login-form"
                    >
                      <UserAddressList
                        setShippingAddress={setShippingAddress}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 order_prices">
                <div className="p-0 pb-20 ml-30 sm:m-0">
                  <form className="apply-coupon" onSubmit={handleSubmitCoupon}>
                    <input
                      type="text"
                      placeholder="Enter Coupon Code..."
                      onChange={e => setCouponText(e.target.value)}
                    />
                    <button
                      type="submit"
                      className="btn btn-md text-black white-space-nowrap"
                      style={{
                        border: "1px solid #ececec",
                        backgroundColor: "#ececec",
                      }}
                    >
                      {couponLoad ? (
                        <FaSpinner className="spinner" />
                      ) : (
                        "Apply Coupon"
                      )}
                    </button>
                  </form>

                  {coupon?.response?.message && (
                    <p className="mx-2 pt-2 text-danger">
                      {coupon?.response?.message}
                    </p>
                  )}
                  {typeof coupon?.response === "number" && (
                    <p className="mx-2 pt-2 text-green">{coupon?.success}</p>
                  )}
                </div>

                {/* <form onSubmit={handleSubmit}> */}
                {/* <button type="submit" disabled={!stripe || !elements}>
                  Pay
                </button> */}
                {/* </form> */}

                {loading ? (
                  <>
                    <ProductLoader height="200px" />
                  </>
                ) : (
                  <div className="border p-40 cart-totals ml-30 mb-30  ">
                    <div className="payment_method">
                      <div className="mb-10">
                        <h5>Order summary</h5>
                      </div>

                      <div className="table-responsive order_table table-border-tr-none max-h:400px">
                        {loading ? (
                          <SmallCardLoader />
                        ) : (
                          <table
                            className={!noStock ? "table no-border" : "d-none"}
                          >
                            <tbody>
                              {handleCartItem()?.map((item, i) => (
                                <CheckoutItem
                                  item={item}
                                  key={i}
                                  isSmall={true}
                                />
                              ))}
                            </tbody>
                          </table>
                        )}
                      </div>
                      <div className="mb-30">
                        <div className="d-flex justify-content-between align-items-center max-w:300px mb-10">
                          <p>Sub total</p>
                          <p>
                            {currencyCode} {userCart?.itemsPrice}
                          </p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center max-w:300px mb-10">
                          <p>Delivery charge</p>
                          {userCart?.shippingPrice > 0 ? (
                            <p>
                              {defaultShippingCharge &&
                              defaultShippingCharge >
                                userCart?.shippingPrice ? (
                                <del>
                                  {" "}
                                  {currencyCode} {defaultShippingCharge}
                                </del>
                              ) : (
                                ""
                              )}
                              &nbsp;&nbsp;
                              {currencyCode} {userCart?.shippingPrice}
                            </p>
                          ) : (
                            <div className="d-flex">
                              {defaultShippingCharge &&
                              defaultShippingCharge >
                                userCart?.shippingPrice ? (
                                <del>
                                  {currencyCode}
                                  {defaultShippingCharge}
                                </del>
                              ) : (
                                ""
                              )}
                              <p className="text-green">&nbsp;Free</p>
                            </div>
                          )}
                        </div>
                        {changePaymentType === "Cash on Delivery" ? (
                          <div className="d-flex justify-content-between align-items-center max-w:300px mb-10">
                            <p>COD Charge</p>
                            <p>
                              {currencyCode} {shipping?.codCharge}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                        {userCart?.couponDiscount ? (
                          <div className="d-flex justify-content-between align-items-center max-w:300px mb-10">
                            <p>Coupon Discount</p>
                            <p>
                              - {currencyCode} {userCart?.couponDiscount}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                        {userCart?.totalDiscount ? (
                          <div className="d-flex justify-content-between align-items-center max-w:300px mb-10">
                            <p>Total Discount</p>
                            <p>
                              - {currencyCode} {userCart?.totalDiscount}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}{" "}
                        {userCart?.totalPrimeDiscount ? (
                          <div className="d-flex justify-content-between align-items-center max-w:300px mb-10">
                            <p>Prime Discount</p>
                            <p>
                              - {currencyCode} {userCart?.totalPrimeDiscount}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="d-flex justify-content-between align-items-center max-w:300px mb-20">
                          <h6>Total</h6>
                          <h6>
                            <h6>
                              {currencyCode}{" "}
                              {changePaymentType === "Cash on Delivery" &&
                              shipping?.codCharge
                                ? userCart?.totalPrice +
                                  (shipping?.codCharge
                                    ? shipping?.codCharge
                                    : 0)
                                : userCart?.totalPrice}
                            </h6>
                          </h6>
                        </div>
                      </div>

                      <div className="d-flex align-items-end sm:flex-col">
                        <div className="payment_option sm:text-start">
                          {/* {otherPaymentOption?.isPayOnChatIntegrated
                            ? PayOnChatInput()
                            : ""} */}
                          {/* {PayOnChatInput()} */}

                          {handlePaymentInput() === "both" && (
                            <>
                              {CashOnDeliveryInput()}
                              {OnlinePaymentInput()}
                            </>
                          )}

                          {handlePaymentInput() === "cod" &&
                            CashOnDeliveryInput()}

                          {handlePaymentInput() === "online" && (
                            <>{OnlinePaymentInput()}</>
                          )}
                        </div>
                      </div>
                      {error?.length > 0 ? (
                        <p className="text-danger font-size-13">{error}</p>
                      ) : (
                        ""
                      )}

                      {width >= 480 ? (
                        <div className="w-100 mt-4">
                          {" "}
                          {changePaymentType === "Online Payment" ? (
                            <>
                              {shippingAddress === null ||
                                (!shippingAddress && (
                                  <p
                                    style={{ fontSize: "14px" }}
                                    className="mb-0"
                                  >
                                    Please select an address to place the order
                                  </p>
                                ))}
                              {stripeInfo?.clientSecret?.length &&
                                stripeInfo?.clientSecret && (
                                  <div className="mb-4">
                                    <PaymentElement />
                                  </div>
                                )}

                              <Button
                                disabled={!shippingAddress && true}
                                className={`btn w-100 btn-fill-out myButton btn-block ml-auto ${
                                  noStock &&
                                  "bg-disable border-none cursor-notAllowed"
                                }`}
                                onClick={() => initiateCheckout("online")}
                              >
                                Pay ({currencyCode} {userCart?.totalPrice})
                                {paymentLoad && (
                                  <FaSpinner className="spinner" />
                                )}
                              </Button>

                              <div className="d-flex my-2 justify-content-evenly align-items-center">
                                <img src={paytm} height={"12px"} />
                                <img src={googlepay} height={"12px"} />
                                <img src={UPI} height={"12px"} />
                                <img src={visa} height={"12px"} />
                                <img src={rupay} height={"17px"} />
                                <img src={mastercard} height={"12px"} />
                                {/* <img src={phonepay} height={"20px"} /> */}
                              </div>
                              <p className="text-center mt-4 d-flex align-items-center justify-content-center">
                                <FaLock className="mx-1" /> secure connection{" "}
                              </p>
                            </>
                          ) : (
                            <Button
                              disabled={!shippingAddress && true}
                              onClick={() => setIsOpen({ status: true })}
                              className={`btn w-100 btn-fill-out myButton btn-block ml-auto btn-md sm:mt-4 ${
                                noStock &&
                                "bg-disable border-none cursor-notAllowed"
                              }`}
                            >
                              {orderLoad && (
                                <FaSpinner className="spinner mx-1" />
                              )}
                              Place Order ({currencyCode}{" "}
                              {userCart?.totalPrice +
                                (shipping?.codCharge ? shipping?.codCharge : 0)}
                              )
                            </Button>
                          )}
                        </div>
                      ) : (
                        //mobile
                        <div className="mobileFlotingBtn flex-column">
                          <p
                            style={{ fontSize: "11px" }}
                            className="text-center d-flex align-items-center justify-content-center"
                          >
                            <FaLock className="" />
                            &nbsp;secure connection{" "}
                          </p>
                          {changePaymentType === "Online Payment" && (
                            <>
                              <Button
                                disabled={!shippingAddress && true}
                                className={`btn btn-fill-out myButton btn-block w-100 ml-auto ${
                                  noStock &&
                                  "bg-disable border-none cursor-notAllowed checkout-btn"
                                }`}
                                onClick={() => initiateCheckout("online")}
                              >
                                Pay ({currencyCode} {userCart?.totalPrice})
                                {customLoader || paymentLoad ? (
                                  <FaSpinner className="spinner" />
                                ) : (
                                  ""
                                )}
                              </Button>
                              <div className="d-flex my-2 w-100 justify-content-evenly align-items-center">
                                <img src={paytm} height={"12px"} />
                                <img src={googlepay} height={"12px"} />
                                <img src={UPI} height={"12px"} />
                                <img src={visa} height={"12px"} />
                                <img src={rupay} height={"17px"} />
                                <img src={mastercard} height={"12px"} />
                                {/* <img src={phonepay} height={"20px"} /> */}
                              </div>
                            </>
                          )}

                          {changePaymentType === "Cash on Delivery" && (
                            <Button
                              disabled={!shippingAddress && true}
                              onClick={() => setIsOpen({ status: true })}
                              className={`btn btn-fill-out myButton w-100 mb-2 btn-block ml-auto  ${
                                noStock &&
                                "bg-disable border-none cursor-notAllowed checkout-btn"
                              }`}
                            >
                              {orderLoad && (
                                <FaSpinner className="spinner mx-1" />
                              )}
                              Place Order ({currencyCode}{" "}
                              {userCart?.totalPrice +
                                (shipping?.codCharge ? shipping?.codCharge : 0)}
                              )
                            </Button>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        <div
          className={`d-flex align-items-center justify-content-center 
            ${
              orderLoad === false
                ? "modal-backdrop fade d-none"
                : "modal-backdrop fade show"
            }
          `}
        >
          <FaSpinner className="mx-2 spinner fullscreenload" />
        </div>
      </Layout>
    </>
  )

  function PayOnChatInput() {
    return (
      <div className="custome-radio">
        <input
          className="form-check-input"
          type="radio"
          name="payment_option"
          id="exampleRadiosPoc"
          value={"Cash on Delivery"}
          checked={changePaymentType === "Cash on Delivery"}
          onChange={e => handlePaymentChange(e.target.value)}
        />

        <label className="form-check-label" htmlFor="exampleRadiosPoc">
          Pay on Chat
        </label>

        {changePaymentType === "Cash on Delivery" && (
          <div className="form-group">
            <UncontrolledTooltip target={"pocIdOne"}>
              Pay on Chat
            </UncontrolledTooltip>
            <p
              id="pocIdOne"
              className="text-black "
              style={{ fontSize: 13, margin: 0, padding: 0 }}
            >
              Pay on Chat എന്നാൽ ഓൺലൈൻ പേയ്‌മെന്റ്‌ ഗേറ്റ് വേക്ക് പകരമായി
              നമുക്കുള്ള ഓപ്ഷനാണ്. WhatsApp വഴി മാന്വൽആയി പേയ്‌മെന്റ് നടത്തി
              വെരിഫൈ ചെയ്യാനുള്ളതാണ് ഈ ഓപ്ഷൻ...! Thank you
            </p>
          </div>
        )}
      </div>
    )
  }

  function CashOnDeliveryInput() {
    return (
      <div className="custome-radio">
        <input
          className="form-check-input"
          type="radio"
          name="payment_option"
          id="exampleRadiosCod"
          value={"Cash on Delivery"}
          checked={changePaymentType === "Cash on Delivery"}
          onChange={e => handlePaymentChange(e.target.value)}
        />
        <label className="form-check-label" htmlFor="exampleRadiosCod">
          Cash on Delivery
        </label>
        {changePaymentType === "Cash on Delivery" && (
          <div className="form-group">
            <p
              className="text-black "
              style={{ fontSize: 13, margin: 0, padding: 0 }}
            >
              COD charge{" "}
              <span style={{ fontWeight: 600 }}>
                {currencyCode} {shipping?.codCharge} extra
              </span>
            </p>
            <p className="text-muted" style={{ fontSize: 13 }}>
              Please select an address and place order
            </p>
          </div>
        )}
      </div>
    )
  }

  function OnlinePaymentInput() {
    return (
      <div className="custome-radio">
        <input
          className="form-check-input"
          type="radio"
          name="Online Payment"
          value={"Online Payment"}
          id="exampleRadiosOnline"
          checked={changePaymentType === "Online Payment"}
          onChange={e => handlePaymentChange(e.target.value)}
        />
        <label className="form-check-label" htmlFor="exampleRadiosOnline">
          Online Payment
        </label>
        {changePaymentType === "Online Payment" && (
          <div className="form-group ">
            <p className="text-muted mt-5">
              You can pay with your Debit / Credit Cards or using UPI
              <br />
            </p>
          </div>
        )}
      </div>
    )
  }
}

export default Checkout
