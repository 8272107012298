import React, { useState } from "react"
import Layout from "components/layout/Layout"

import contact2 from "../../assets/imgs/page/contact-2.png"
import axios from "axios"
import { showToasterAction } from "store/actions"
import { useDispatch, useSelector } from "react-redux"
import Gmap from "components/elements/Gmap"
import UseGmap from "components/hooks/useGmap"

function Contact() {
  const dispatch = useDispatch()

  const { company } = useSelector(state => ({
    company: state?.SiteSettings.siteSettings?.company,
  }))

  console.log(company)

  const [sendData, setSendData] = useState({
    enq_name: "",
    enq_phone: "",
    enq_email: "",
    enq_message: "",
  })

  const [loading, setLoading] = useState(false)

  const handleChange = e => {
    setSendData({ ...sendData, [e.target.name]: e.target.value })
  }

  function sendEmail(e, v) {
    setLoading(true)
    e.preventDefault()

    axios
      .post(
        "https://mail-sender.vingb.com/send/65aa060d-34e3-41ad-b2e9-6c69a9744c0b",
        sendData
      )
      .then(response => {
        dispatch(
          showToasterAction({
            data: { title: "", message: "message send suucessfully" },
            type: "success",
          })
        )
        setLoading(false)
      })
      .catch(err => {
        dispatch(
          showToasterAction({
            data: { title: "", message: "please try again" },
            type: "warning",
          })
        )
        setLoading(false)
      })
  }

  return (
    <>
      <Layout parent="Home" sub="Pages" subChild="Contact">
        <div className="page-content pt-50">
          {/* <div className="container">
            <div className="row">
              <div className="col-xl-10 col-lg-12 m-auto">
                <section className="row align-items-end mb-50">
                  <div className="col-lg-4 mb-lg-0 mb-md-5 mb-sm-5">
                    <h4 className="mb-20 text-brand">How can help you ?</h4>
                    <h1 className="mb-30">Let us know how we can help you</h1>
                    <p className="mb-20">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Ut elit tellus, luctus nec ullamcorper mattis, pulvinar
                      dapibus leo.
                    </p>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Ut elit tellus, luctus nec ullamcorper mattis, pulvinar
                      dapibus leo.
                    </p>
                  </div>
                  <div className="col-lg-8">
                    <div className="row">
                      <div className="col-lg-6 mb-4">
                        <h5 className="mb-20">01. Visit Feedback</h5>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Ut elit tellus, luctus nec ullamcorper mattis,
                          pulvinar dapibus leo.
                        </p>
                      </div>
                      <div className="col-lg-6 mb-4">
                        <h5 className="mb-20">02. Employer Services</h5>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Ut elit tellus, luctus nec ullamcorper mattis,
                          pulvinar dapibus leo.
                        </p>
                      </div>
                      <div className="col-lg-6 mb-lg-0 mb-4">
                        <h5 className="mb-20 text-brand">
                          03. Billing Inquiries
                        </h5>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Ut elit tellus, luctus nec ullamcorper mattis,
                          pulvinar dapibus leo.
                        </p>
                      </div>
                      <div className="col-lg-6">
                        <h5 className="mb-20">04.General Inquiries</h5>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Ut elit tellus, luctus nec ullamcorper mattis,
                          pulvinar dapibus leo.
                        </p>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
          <section className="container mb-50 d-none d-md-block">
            <div className="border-radius-15 overflow-hidden">
              <div id="map-panes" className="leaflet-map">
                <Gmap />
              </div>
            </div>
          </section> */}
          <div className="container">
            <div className="row">
              <div className="col-xl-10 col-lg-12 m-auto">
                <section className="mb-50">
                  <div className="row mb-60">
                    <div className="col-md-4 mb-4 mb-md-0">
                      <h5 className="mb-15 text-brand">Corporate Office</h5>
                      <div style={{ width: "200px" }}>
                        <a href="#!"> {company?.name}</a>,&nbsp;
                        {company?.address?.addressLine1}{" "}
                        {company?.address?.district}
                      </div>
                      {company?.address?.state} {company?.address?.pinCode}
                      <br />
                      <abbr title="Phone">
                        Phone: {company?.contactDetails?.mobile}{" "}
                      </abbr>
                      <br />
                      <abbr title="Email">Email: </abbr>
                      {company?.contactDetails?.email}
                    </div>

                    <div className="col-md-4 mb-4 mb-md-0">
                      <h5 className="mb-15 text-brand">Factory</h5>
                      <div style={{ width: "240px" }}>
                        {company?.address?.addressLine2}
                      </div>
                    </div>

                    <div className="col-md-4 mb-4 mb-md-0">
                      <div className="border-radius-15 overflow-hidden">
                        <div id="map-panes" className="leaflet-map">
                          <a
                            href="https://www.google.com/maps/place/osperb+innovations/@11.0409982,76.084936,15z/data=!4m5!3m4!1s0x0:0xb015105be9820fed!8m2!3d11.0409982!4d76.084936"
                            target={"_blank"}
                            rel="noreferrer"
                          >
                            <UseGmap />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-8">
                      <div className="contact-from-area padding-20-row-col">
                        <h4 className="mb-10">Drop Us a Line</h4>
                        <p className="text-muted mb-30 font-sm">
                          Your email address will not be published. Required
                          fields are marked *
                        </p>
                        <form
                          className="contact-form-style mt-30"
                          id="contact-form"
                          onSubmit={sendEmail}
                        >
                          <div className="row">
                            <div className="col-lg-6 col-md-6">
                              <div className="input-style mb-20">
                                <input
                                  placeholder="First Name *"
                                  type="text"
                                  name="enq_name"
                                  value={sendData?.enq_name}
                                  onChange={handleChange}
                                  required={true}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="input-style mb-20">
                                <input
                                  placeholder="Your Email *"
                                  type="email"
                                  color="lightBlue"
                                  name="enq_email"
                                  onChange={handleChange}
                                  value={sendData?.enq_email}
                                  required={true}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="input-style mb-20">
                                <input
                                  placeholder="Your Phone *"
                                  type="number"
                                  min={0}
                                  minLength={10}
                                  maxLength={10}
                                  name="enq_phone"
                                  value={sendData?.enq_phone}
                                  onChange={handleChange}
                                  required={true}
                                />
                              </div>
                            </div>
                            {/* <div className="col-lg-6 col-md-6">
                              <div className="input-style mb-20">
                                <input
                                  name="subject"
                                  placeholder="Subject"
                                  type="text"
                                />
                              </div>
                            </div> */}
                            <div className="col-lg-12 col-md-12">
                              <div className="textarea-style mb-30">
                                <textarea
                                  placeholder="Message"
                                  name="enq_message"
                                  onChange={handleChange}
                                ></textarea>
                              </div>
                              <button
                                className="submit submit-auto-width"
                                type="submit"
                              >
                                {loading
                                  ? "Sending message..."
                                  : "Send message"}
                              </button>
                            </div>
                          </div>
                        </form>
                        <p className="form-messege"></p>
                      </div>
                    </div>
                    <div className="col-lg-4 pl-50 d-lg-block d-none">
                      <img
                        className="border-radius-15 mt-50"
                        src={contact2}
                        alt=""
                      />
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Contact
