import { connect, useDispatch, useSelector } from "react-redux"
import Layout from "../../components/layout/Layout"
import React, { useEffect } from "react"
import CartItemWeb from "components/elements/CartItemWeb"
import { useWindowSize } from "react-use"
import CartItemMob from "components/elements/CartItemMob"
import { getLocalCart, getUserCart, showLoginPopupAction } from "store/actions"
import usePrice from "components/hooks/usePrice"
import { Link, useHistory } from "react-router-dom"
import EmptyCartSvg from "components/Common/emptyCart"
import useLangSwitch from "components/hooks/useLanguageSwicth"
import { useLocalCartPirces } from "components/hooks/useCartAction"
import { AiOutlineLogin } from "react-icons/ai"
import ProductLoader from "components/Common/loaders/ProductLoader"

const Cart = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const token = localStorage.getItem("idealeshope_token")

  const {
    userCart,
    localCart,
    loading,
    cartItems,
    specialMessages,
    shipping,
    freeShippingThreshold,
  } = useSelector(state => ({
    userCart: state.Cart.userCart,
    localCart: state.Cart.localCart,
    loading: state.Cart.loading,
    cartItems: state.Cart.cartItems,
    specialMessages: state.SiteSettings.siteSettings.specialMessages,
    freeShippingThreshold:
      state.SiteSettings.siteSettings.shipping?.freeShippingThreshold,
    shipping: state.SiteSettings.siteSettings.shipping,
  }))

  const defaultShippingCharge = shipping?.defaultShippingCharge

  const { width } = useWindowSize()

  useEffect(() => {
    dispatch(getUserCart())
  }, [dispatch, cartItems?.length])

  useEffect(() => {
    if (!token) {
      dispatch(getLocalCart())
    }
  }, [])

  const { currencyCode } = usePrice()

  const language = useLangSwitch(
    specialMessages?.primaryLang,
    specialMessages?.secondaryLang
  )

  const outOfStock = cartItems?.filter(i => i.isOutOfStock === true)

  const handleCheckout = () => {
    if (cartItems?.length >= 1 && outOfStock?.length === 0) {
      history.push("/checkout")
    } else {
      history.push("/checkout/login")
    }
  }

  const handleCartItems = () => {
    if (cartItems?.length >= 1 && cartItems !== "undefined") {
      return cartItems
    } else {
      return localCart?.filter(i => i.quantity !== 0)
    }
  }

  useEffect(() => {
    const removeQtyZeroItem = localCart?.filter(item => item?.quantity !== 0)
    console.log(removeQtyZeroItem)
  }, [localCart])

  const { itemTotal } = useLocalCartPirces()

  const handleLoginPopup = () => dispatch(showLoginPopupAction())

  const FinalItemTotal =
    itemTotal + (itemTotal < freeShippingThreshold ? defaultShippingCharge : 0)

  const addMorePrice =
    freeShippingThreshold -
    (userCart?.itemsPrice ? userCart?.itemsPrice : itemTotal)

  const handleDelivery = () => {
    if (token) {
      return userCart?.shippingPrice
    } else {
      if (defaultShippingCharge) {
        if (itemTotal < freeShippingThreshold) {
          return defaultShippingCharge
        }
      }
    }
  }

  return (
    <>
      <Layout parent="Home" sub="Shop" subChild="Cart">
        <section className="mb-50 myCart">
          <div className="container p-5 pt-4">
            <div className="row">
              <div className="col-lg-8 mb-20">
                <h5 className="heading-2">Your Cart</h5>
              </div>
            </div>
            <div className="row">
              {handleCartItems()?.length >= 1 ? (
                <>
                  <div className="col-lg-8 mb-4 pb-4 sm:p-0">
                    {loading ? (
                      <ProductLoader height="200px" />
                    ) : (
                      <>
                        <div className="table-responsive shopping-summery">
                          {width > 480 ? (
                            <CartItemWeb cartItems={handleCartItems()} />
                          ) : (
                            <div className="p-1">
                              {handleCartItems()?.map((item, i) => (
                                <CartItemMob item={item} key={i} />
                              ))}
                            </div>
                          )}
                        </div>

                        {specialMessages?.primaryLang?.deliveryOffer && (
                          <div className="cart-action mb-2 text-end d-flex justify-content-between align-items-center border-none">
                            <Link
                              to={"#"}
                              className="btn  sm:font-size-11 bg-transperant text-black border-1-gray rounded-xs sm:m-auto"
                            >
                              <span
                                onClick={handleLoginPopup}
                                className="text-green mx-3"
                              >
                                {specialMessages?.primaryLang?.deliveryOffer}
                              </span>
                            </Link>
                          </div>
                        )}

                        <div className="cart-action text-end d-flex justify-content-between align-items-center border-none">
                          {!token ? (
                            <Link
                              to={"#"}
                              className="btn sm:font-size-11 sm:m-auto sm:p-10 bg-transperant text-black border-1-gray rounded-xs"
                            >
                              <AiOutlineLogin className="mr-10" />
                              Login to see items from your existing bag{" "}
                              <span
                                onClick={handleLoginPopup}
                                className="text-green mx-3"
                              >
                                Login Now
                              </span>
                            </Link>
                          ) : (
                            <Link
                              to={"#"}
                              className="btn bg-transperant text-white"
                            ></Link>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                  <div className="col-lg-3 col-12 flex-1 mx-4 sm:m-0 sm:p-0">
                    {loading ? (
                      <ProductLoader height="200px" />
                    ) : (
                      <div className="">
                        <div className="border p-md-4 p-30 border-radius cart-totals">
                          <div className="heading_s1 mb-3">
                            <h4>Cart Summary</h4>

                            <p>
                              Price details ({handleCartItems()?.length} Item)
                            </p>
                            {userCart?.couponCode && (
                              <p className="text-brand">
                                Coupon <span>{userCart?.couponCode}</span> is
                                Applied
                              </p>
                            )}
                          </div>
                          {width >= 768 ? (
                            <div className="table-responsive">
                              <table className="table table-border-none">
                                <tbody>
                                  <tr className="sm:d-flex">
                                    <td className="cart_total_label ">
                                      Cart Subtotal{" "}
                                    </td>
                                    <td className="cart_total_label sm:w-fit">
                                      :{" "}
                                    </td>

                                    <td className="cart_total_amount">
                                      <span className="font-lg fw-800 text-black ">
                                        {currencyCode}{" "}
                                        {userCart?.itemsPrice
                                          ? userCart?.itemsPrice
                                          : itemTotal}
                                      </span>
                                    </td>
                                  </tr>

                                  <tr className="sm:d-flex">
                                    <td className="cart_total_label">
                                      Delivery Charge
                                    </td>
                                    <td className="cart_total_label sm:w-fit">
                                      :
                                    </td>
                                    <td className="cart_total_amount text-black">
                                      <>
                                        {userCart?.shippingPrice > 0 ? (
                                          <>
                                            {defaultShippingCharge &&
                                            defaultShippingCharge >
                                              userCart?.shippingPrice ? (
                                              <del>
                                                {currencyCode}{" "}
                                                {defaultShippingCharge}
                                              </del>
                                            ) : (
                                              ""
                                            )}
                                            &nbsp;
                                            {currencyCode}{" "}
                                            {userCart?.shippingPrice ?? 0}
                                          </>
                                        ) : (
                                          <div className="d-flex">
                                            {handleDelivery() ? (
                                              <span>
                                                {currencyCode}{" "}
                                                {handleDelivery()}
                                              </span>
                                            ) : (
                                              <p className="text-green">
                                                &nbsp;Free
                                              </p>
                                            )}
                                          </div>
                                        )}
                                      </>
                                    </td>
                                  </tr>

                                  {userCart?.couponDiscount ? (
                                    <tr className="sm:d-flex">
                                      <td className="cart_total_label">
                                        Coupon Discount
                                      </td>
                                      <td className="cart_total_label sm:w-fit">
                                        :
                                      </td>
                                      <td className="cart_total_amount text-brand">
                                        - {currencyCode}{" "}
                                        {userCart?.couponDiscount}
                                      </td>
                                    </tr>
                                  ) : (
                                    ""
                                  )}

                                  <tr className="sm:d-flex ">
                                    <td className="cart_total_label pt-4">
                                      <h6>Total</h6>
                                    </td>
                                    <td className="cart_total_label sm:w-fit  pt-4">
                                      <h6>:</h6>
                                    </td>

                                    <td className="cart_total_amount pt-4">
                                      <strong>
                                        <h6>
                                          <span className="font-xl fw-400 text-black">
                                            {" "}
                                            {currencyCode}{" "}
                                            {userCart?.totalPrice
                                              ? userCart?.totalPrice
                                              : FinalItemTotal}
                                          </span>
                                        </h6>
                                      </strong>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            <div className="mobile_total">
                              <div className="mobile_total_item">
                                <p>Cart Subtotal</p>
                                <span className="">
                                  {currencyCode}{" "}
                                  {userCart?.itemsPrice
                                    ? userCart?.itemsPrice
                                    : itemTotal}
                                </span>
                              </div>
                              {userCart?.couponDiscount ? (
                                <div className="mobile_total_item">
                                  <p>Coupon Discount</p>
                                  <span className="text-green">
                                    {" "}
                                    - {currencyCode} {userCart?.couponDiscount}
                                  </span>
                                </div>
                              ) : (
                                ""
                              )}
                              <div className="mobile_total_item">
                                <p>Delivery Charge</p>

                                {userCart?.shippingPrice > 0 ? (
                                  <span>
                                    {defaultShippingCharge &&
                                    defaultShippingCharge >
                                      userCart?.shippingPrice ? (
                                      <del>
                                        {currencyCode}
                                        {defaultShippingCharge}
                                      </del>
                                    ) : (
                                      ""
                                    )}
                                    &nbsp;
                                    {currencyCode}{" "}
                                    {userCart?.shippingPrice ?? 0}
                                  </span>
                                ) : (
                                  <>
                                    <div className="d-flex">
                                      {handleDelivery() ? (
                                        <span>
                                          {currencyCode} {handleDelivery()}
                                        </span>
                                      ) : (
                                        <p className="text-green">&nbsp;Free</p>
                                      )}
                                    </div>
                                  </>
                                )}
                              </div>

                              <div className="mobile_total_item mt-2">
                                <p className="fw-900">Total</p>
                                <span className="fw-900 text-black">
                                  {" "}
                                  {currencyCode}{" "}
                                  {userCart?.totalPrice
                                    ? userCart?.totalPrice
                                    : FinalItemTotal}
                                </span>
                              </div>
                              {userCart?.totalDiscount ? (
                                <div className="youSave text-green">
                                  <span>
                                    You will save {currencyCode}{" "}
                                    {userCart?.totalDiscount} on this order{" "}
                                  </span>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          )}
                          <div className="d-flex mb-2">
                            <Link
                              to={"#!"}
                              onClick={handleCheckout}
                              className={`btn w-100 bg-green m-auto  ${
                                outOfStock?.length >= 1 &&
                                "bg-disable border-none"
                              }`}
                            >
                              <i className="fi-rs-box-alt mr-10"></i>
                              Proceed To Checkout
                            </Link>
                          </div>

                          {/* {addMorePrice > 0 && (
                            <span className="text-black">
                              Add items worth{" "}
                              <span
                                style={{ fontWeight: "700" }}
                                className="text-green"
                              >
                                {currencyCode}
                                {addMorePrice}
                              </span>{" "}
                              for free shipping
                            </span>
                          )} */}
                        </div>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <EmptyCartSvg
                  style={{
                    isDes: true,
                    height: "400px",
                    margin: "auto",
                    width: "fit-content",
                    marginTop: "2rem",
                  }}
                />
              )}
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

const mapStateToProps = state => ({
  cartItems: state?.cart,
  activeCart: state?.counter,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Cart)
