import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Modal, ModalBody, UncontrolledTooltip } from "reactstrap"
import deleteImg from "assets/imgs/other/delete.png"
import { FaSpinner } from "react-icons/fa"
import CheckoutItem from "./checkoutItem"
import usePrice from "components/hooks/usePrice"
import LazyLoad from "react-lazyload"
import { getOtherPaymentOptions } from "store/actions"
import { useDispatch, useSelector } from "react-redux"

const CodConfirmPopup = ({
  onConfirmClick,
  onCloseClick,
  show,
  loading,
  data,
}) => {
  const {
    handleCartItem,
    userCart,
    defaultShippingCharge,
    shipping,
    changePaymentType,
    shippingAddress,
  } = data
  const { currencyCode } = usePrice()
  const dispatch = useDispatch()

  const { otherPaymentOption } = useSelector(state => ({
    otherPaymentOption: state.SiteSettings.otherPaymentOptions,
  }))

  // console.log(Object?.values(shippingAddress))

  useEffect(() => {
    dispatch(getOtherPaymentOptions())
  }, [dispatch])

  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      centered={true}
      style={deleteModalStyle}
      className="delete-modal cod-confirm-modal"
    >
      <ModalBody style={deleteModalBodyStyle} className="p-0">
        <div className="modal-scroll-div">
          <div className="d-flex align-items-center justify-content-center mt-4 mb-4 pt-2 pb-2">
            <div className="text-center px-3 d-flex align-items-center position-relative ">
              <div>
                <h6>Confirm Order</h6>
                <p
                  className="m-auto font-size-13 text-muted"
                  style={{
                    lineHeight: "15px",
                  }}
                >
                  {"Please confirm the order details below"}
                </p>
              </div>
              <div
                style={{ borderRadius: ".5rem", right: -45 }}
                className="position-absolute"
              >
                <LazyLoad height={100}>
                  <img
                    src={deleteImg}
                    className="p-0 cod-confirm-modal-image"
                    alt="delete-image"
                  />
                </LazyLoad>
              </div>
            </div>
          </div>
          <div>
            <div className="px-4">
              <p className="font-size-13">Delivery Address</p>
              <div className="d-flex flex-wrap mb-1 myAddress">
                {shippingAddress &&
                  Object?.values(shippingAddress)?.map((address, key) => (
                    <span key={key} className="mb-0">
                      {address}&nbsp;&nbsp;
                    </span>
                  ))}
              </div>
            </div>
            <p className="font-size-13 px-4 mt-3 mb-0">
              Payment details&nbsp;&nbsp;
              <span
                className="bg-secondary-color text-white mx-2"
                style={{
                  padding: 5,
                  borderRadius: 5,
                }}
              >
                {otherPaymentOption?.isPayOnChatIntegrated
                  ? "Pay on Chat"
                  : "Cash on Delivery"}
              </span>
            </p>
            <div className="border p-1 m-3 sm:m-0 sm:mt-0 sm:pt-0 cart-totals modal-order-summery">
              <div className="payment_method">
                <div className="table-responsive order_table table-border-tr-none">
                  <table className={"table no-border"}>
                    <tbody>
                      {handleCartItem?.map((item, i) => (
                        <CheckoutItem
                          item={item}
                          key={i}
                          isSmall={true}
                          isModal={true}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="mb-0">
                  <div className="d-flex justify-content-between align-items-center mb-10">
                    <p className="font-size-14">Sub total</p>
                    <p>
                      {currencyCode} {userCart?.itemsPrice}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mb-10">
                    <p className="font-size-14">Delivery charge</p>
                    {userCart?.shippingPrice > 0 ? (
                      <p>
                        {defaultShippingCharge &&
                        defaultShippingCharge > userCart?.shippingPrice ? (
                          <del>
                            {" "}
                            {currencyCode} {defaultShippingCharge}
                          </del>
                        ) : (
                          ""
                        )}
                        &nbsp;&nbsp;
                        {currencyCode} {userCart?.shippingPrice}
                      </p>
                    ) : (
                      <div className="d-flex">
                        {defaultShippingCharge &&
                        defaultShippingCharge > userCart?.shippingPrice ? (
                          <del>
                            {currencyCode}
                            {defaultShippingCharge}
                          </del>
                        ) : (
                          ""
                        )}
                        <p className="text-green">&nbsp;Free</p>
                      </div>
                    )}
                  </div>
                  {changePaymentType === "Cash on Delivery" ? (
                    <>
                      {otherPaymentOption?.isPayOnChatIntegrated ? (
                        <div className="d-flex justify-content-between align-items-center mb-10">
                          <UncontrolledTooltip target={"pocIdThree"}>
                            Pay on Chat
                          </UncontrolledTooltip>
                          <p className="font-size-14" id="pocIdThree">
                            POC Charge
                          </p>
                          <p>
                            {currencyCode} {shipping?.codCharge}
                          </p>
                        </div>
                      ) : (
                        <div className="d-flex justify-content-between align-items-center mb-10">
                          <UncontrolledTooltip target={"pocIdThree"}>
                            Cash on Delivery
                          </UncontrolledTooltip>
                          <p className="font-size-14" id="pocIdThree">
                            COD Charge
                          </p>
                          <p>
                            {currencyCode} {shipping?.codCharge}
                          </p>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {userCart?.totalDiscount ? (
                    <div className="d-flex justify-content-between align-items-center mb-10">
                      <p className="font-size-14">Total Discount</p>
                      <p>
                        - {currencyCode} {userCart?.totalDiscount}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}{" "}
                  <div className="d-flex justify-content-between align-items-center mb-20">
                    <h6>Total</h6>
                    <h6>
                      {currencyCode}{" "}
                      {changePaymentType === "Cash on Delivery" &&
                      shipping?.codCharge
                        ? userCart?.totalPrice + shipping?.codCharge
                        : userCart?.totalPrice}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex gap-2 mt-3 p-3 sm:m-0">
          <button
            className="btn text-black w-100"
            style={{
              borderRadius: 10,
              background: "transparent",
              border: "1px solid #dee2e6",
            }}
            onClick={onCloseClick}
          >
            Cancel
          </button>
          <button
            onClick={onConfirmClick}
            style={{ borderRadius: 10 }}
            className="btn bg-green w-100 popup-confirm-button"
          >
            Confirm&nbsp;
            {loading && <FaSpinner className="mx-2 spinner" />}
          </button>
        </div>
      </ModalBody>
    </Modal>
  )
}

CodConfirmPopup.propTypes = {
  onCloseClick: PropTypes.func,
  onConfirmClick: PropTypes.func,
  show: PropTypes.bool,
  loading: PropTypes.bool,
  data: PropTypes.any,
}

export default CodConfirmPopup

const deleteModalStyle = {
  width: 300,
}
const deleteModalBodyStyle = {
  minHeight: 300,
}
