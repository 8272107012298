import usePrice from "components/hooks/usePrice"
import { map } from "lodash"
import React from "react"
import { BsDot } from "react-icons/bs"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { handleOrderStatus } from "./OrderItemMob"

function OrderSuccessMob() {
  const { orders, totalOrders, order } = useSelector(state => ({
    orders: state.Cart.allOrder.orders,
    totalOrders: state.Cart.allOrder.totalOrders,
    order: state.Cart.order,
  }))

  const { currencyCode } = usePrice()

  const orderItems = order?.orderItems

  return (
    <>
      {map(orderItems, (orderItem, key) => (
        <div
          className="border-b-4 py-3 bg-lightGray px-3 mb-2 rounded-sm mobileOrderCard"
          key={key}
        >
          <div
            className={`d-flex align-items-center justify-content-end ${handleOrderStatus(
              orderItem?.orderItemStatus
            )}`}
          >
            <BsDot size={"1.2rem"} />
            <p className="font-smaller">{orderItem?.orderItemStatus}</p>
          </div>
          <div className="">
            <p className="mb-0">
              {" "}
              <Link
                to="#!"
                className="text-black "
                style={{ fontWeight: "600" }}
              >
                {orderItem?.primaryLang?.name}
              </Link>
              <br />
              <span className="mb-0">
                {orderItem?.varientValue && <span>
                  ({orderItem?.varientValue} {orderItem?.varientUnit}
                  )&nbsp;&nbsp;
                </span>}
                {currencyCode} {orderItem?.price}
              </span>
            </p>

            <p className="text-success mb-0">{orderItem?.quantity} qty</p>
            <div className="d-flex align-items-center mt-2 justify-content-end">
              <span>
                <h4>
                  {currencyCode} {orderItem?.price * orderItem?.quantity}
                </h4>
              </span>
            </div>
          </div>
        </div>
      ))}
    </>
  )
}

export default OrderSuccessMob
