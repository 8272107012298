import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { map } from "lodash"
import usePrice from "components/hooks/usePrice"
import { Link } from "react-router-dom"
import Rating from "react-rating"
import { FaStar } from "react-icons/fa"
import { FaRegStar } from "react-icons/fa"
import TrackMyOrderButton from "components/elements/trackOrder"
import { FaSpinner } from "react-icons/fa"
import moment from "moment"

export const handleOrderItemStatus = status => {
  switch (status) {
    case "Cancelled":
    case "Cancel Processing":
    case "Returned":
    case "Return Processing":
      return true
    default:
      return false
  }
}

function OrderContentMobile({ setReviewPopup }) {
  const dispatch = useDispatch()
  const { currencyCode } = usePrice()

  const { order, shipping, orderLoad } = useSelector(state => ({
    order: state.Cart.order,
    orderLoad: state.Cart.orderLoad,
    shipping: state.SiteSettings.siteSettings.shipping,
  }))

  const orderItems = order?.orderItems
  const defaultShippingCharge = shipping?.defaultShippingCharge

  const [isShow, setIsShow] = useState({ status: false })

  const address = order?.shippingInfo

  const [selectedItemIds, setSelectedItemIds] = useState([])
  const [isProcessing, setIsProcessing] = useState()

  useEffect(() => {
    const filteredItem = orderItems?.filter(
      i => i.orderItemStatus === "Processing"
    )
    const ids = filteredItem?.map(i => i._id)

    setSelectedItemIds(ids)

    if (filteredItem?.length >= 1) {
      setIsProcessing(true)
    } else {
      setIsProcessing(false)
    }
  }, [orderItems])

  const handleCancelOrder = () => {
    if (selectedItemIds?.length >= 1) {
      const input = {
        id: order?._id,
        cancelOrderItems: selectedItemIds,
      }
      dispatch(cancelOrder(input))
    }
  }

  const handleReturnOrder = () => {
    setIsShow({ ...isShow, status: true })
  }

  const today = new Date()

  const todayDate = moment(today).format("DD MM YYYY")

  const orderedDate = moment(order?.createdAt).format("DD MM YYYY")

  const [review, setReview] = useState({
    productId: "",
    review: 0,
  })

  const ratingChanged = (newRating, item) => {
    setReview({
      productId: item?.product,
      review: newRating,
    })
    setReviewPopup({
      status: true,
      item: {
        product: item,
        orderId: order?._id,
        review: newRating,
        setReview: setReview,
      },
    })
  }

  return (
    <div className="card-body">
      <div className="d-flex align-items-start">
        <div className="d-flex flex-column justify-content-between align-items-start">
          <p className="font-normal mb-0">
            Order ID: <span className="text">{order?.orderId}</span>
          </p>

          <p className="font-normal mb-0" style={{ lineHeight: "20px" }}>
            <span className="text-black">{order?.paymentType}</span>
          </p>

          {order?.paymentType === "Cash on Delivery" && (
            <div className="text-end">
              <p className="font-normal mb-0">
                {" "}
                {order?.isPaid ? (
                  <span className="text-brand">Paid</span>
                ) : (
                  <span className="text-warning">Not Paid</span>
                )}
              </p>
            </div>
          )}
          <p className="font-normal mb-3 mt-2 " style={{ width: "65%" }}>
            Order Placed{" "}
            {orderedDate === todayDate
              ? "Today " + moment(order?.createdAt).format("LT")
              : moment(order?.createdAt).format("dddd, DD MMMM LT")}
          </p>
        </div>
        {order?.orderStatus === "Shipped" && order?.awbNumber && (
          <TrackMyOrderButton
            className="btn btn-lg btn-custom bg-green text-white hover-up border-1-gray"
            awbNumber={order?.awbNumber}
          />
        )}
      </div>

      {map(orderItems, (orderItem, key) => (
        <div
          key={key}
          className=" mb-2"
          style={{ backgroundColor: "#f3f4f4", padding: "15px 20px" }}
        >
          <div className="d-flex align-items-start justify-content-between rounded-sm mb-2">
            <div style={{ flex: 1 }} className="pe-4">
              <h6 style={{ fontWeight: 400 }} className="sm:font-14">
                {orderItem?.primaryLang?.name}{" "}
                {orderItem?.varientValue && (
                  <small>
                    ({orderItem?.varientValue} {orderItem?.varientUnit})
                  </small>
                )}
              </h6>
              <span
                className={
                  "text-start " +
                  `${
                    handleOrderItemStatus(orderItem?.orderItemStatus)
                      ? "text-danger"
                      : "text-info"
                  }`
                }
              >
                {orderItem?.orderItemStatus}
              </span>
              <p className="mt-2 mb-0">{orderItem?.quantity} qty</p>

              <h6 className="text-green">
                {currencyCode} {orderItem?.price * orderItem?.quantity}
              </h6>
            </div>
            <div>
              <img
                src={orderItem?.imageUrl}
                alt="ideal-order-item"
                height={"100px"}
                width={"100px"}
                className="rounded-sm"
                style={{
                  filter:
                    handleOrderItemStatus(orderItem?.orderItemStatus) &&
                    "grayscale(1)",
                }}
                title="ideal-item"
                loading="eager"
              />
            </div>
          </div>

          <div style={{ borderTop: "1px solid #e2e2e2" }} className="pt-2">
            {orderItem?.isReviewed === true ? (
              <>
                <div className="d-flex align-items-end justify-content-between rating-star mb-1">
                  <div className="d-flex w-100">
                    <span className="me-3 pt-1" style={{ fontSize: 12 }}>
                      Rate Product
                    </span>
                    <Rating
                      emptySymbol={<FaRegStar />}
                      fullSymbol={<FaStar />}
                      className="text-warning"
                      initialRating={
                        orderItem?.review?.rating
                          ? orderItem?.review?.rating
                          : 0
                      }
                      readonly
                    />
                  </div>
                  <span className="text-green">Reviewed</span>
                </div>
              </>
            ) : (
              <>
                {orderItem?.orderItemStatus === "Delivered" && (
                  <div className="d-flex align-items-end rating-star mb-1">
                    <span className="me-3 pt-1">Rate Product</span>
                    <Rating
                      emptySymbol={<FaRegStar />}
                      fullSymbol={<FaStar />}
                      className="text-warning"
                      initialRating={
                        orderItem?.product === review.productId && review.review
                      }
                      onChange={review => ratingChanged(review, orderItem)}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      ))}

      <a className={""}>
        <div className="w-100 d-flex align-item-center justify-content-end">
          {order?.orderStatus === "Processing" && isProcessing === true && (
            <Link
              to={"#!"}
              id="invoice_download_btn"
              onClick={() => handleCancelOrder()}
              style={{ fontWeight: "100" }}
              className="text-start w-100 my-3 btn-custom text-black "
            >
              Cancel
              {orderLoad && <FaSpinner className="mx-2 spinner" />}
            </Link>
          )}
          {order?.orderStatus === "Delivered" && (
            <Link
              to={"#!"}
              id="invoice_download_btn"
              onClick={() => handleReturnOrder()}
              className="btn btn-lg btn-custom bg-light text-black hover-up border-1-gray"
            >
              Return
              {orderLoad && <FaSpinner className="mx-2 spinner" />}
            </Link>
          )}
        </div>
      </a>

      {/* <div>
                {order?.orderStatus === "Returned" ||
                order?.orderStatus === "Cancelled" ? (
                  <></>
                ) : (
                  <TrackOrder />
                )}
              </div> */}

      <p className="font-normal mb-2">Shipping Details</p>

      <div
        className="d-flex align-items-start justify-content-between mb-4 rounded-sm"
        style={{ backgroundColor: "#f3f4f4", padding: "15px 20px" }}
      >
        <div style={{ flex: 1 }} className="pe-4">
          <strong className="text-black text-size-20">{address?.name}</strong>{" "}
          <p className="font-normal">{address?.contactNumber}</p>
          <p className="font-normal">{address?.email}</p>
          {address?.address}
        </div>
      </div>

      <p className="font-normal mb-2">Price Details</p>

      <div
        className="d-flex flex-column align-items-start justify-content-between mb-2 rounded-sm"
        style={{ backgroundColor: "#f3f4f4", padding: "15px 20px" }}
      >
        <div className="d-flex w-100 justify-content-between">
          <span>Sub Total</span>
          <span>
            {currencyCode} {order?.itemsPrice}
          </span>
        </div>
        <div className="d-flex w-100 justify-content-between">
          <span>Shipping</span>
          <span>
            {defaultShippingCharge > order?.shippingPrice && (
              <del>
                {" "}
                {currencyCode}
                {defaultShippingCharge}
              </del>
            )}
            &nbsp;
            {order?.shippingPrice >= 1 ? (
              <>
                {currencyCode}
                {order?.shippingPrice}
              </>
            ) : (
              <span className="text-green">&nbsp;FREE</span>
            )}
          </span>
        </div>
        {order?.totalofferDiscount ? (
          <div className="d-flex w-100 justify-content-between">
            <span> Offer Discount</span>
            <span>
              {currencyCode} {order?.totalofferDiscount}
            </span>
          </div>
        ) : (
          ""
        )}
        {order?.couponDiscount ? (
          <div className="d-flex w-100 justify-content-between">
            <span> Coupon Discount</span>
            <span>
              - {currencyCode} {order?.couponDiscount}
            </span>
          </div>
        ) : (
          ""
        )}

        {order?.totalprimeDiscount ? (
          <div className="d-flex w-100 justify-content-between">
            <span> Prime Discount</span>
            <span>
              {currencyCode} {order?.totalprimeDiscount}
            </span>
          </div>
        ) : (
          ""
        )}

        {order?.totalDiscount ? (
          <div className="d-flex w-100 justify-content-between">
            <span>Discount</span>
            <span>
              {currencyCode} {order?.totalDiscount}
            </span>
          </div>
        ) : (
          ""
        )}

        <div className="d-flex w-100 justify-content-between mt-2">
          <span className="flex-1 d-flex font-normal">
            {" "}
            Grand Total (
            <p className="mb-0 text-muted font-small">Taxes Included</p>)
          </span>

          <h5 className="mt-0 mb-0 text-brand">
            {currencyCode} {order?.totalPrice}
          </h5>
        </div>
      </div>
    </div>
  )
}

export default OrderContentMobile

OrderContentMobile.propTypes = {
  setReviewPopup: PropTypes.func,
}
