import React, { useEffect } from "react"
import SwiperCore, { Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { useDispatch, useSelector } from "react-redux"
import { getBanners } from "store/actions"
import { map } from "lodash"

import BannerLoader from "components/Common/loaders/BannerLoader"

import "swiper/swiper.min.css"
import "swiper/swiper-bundle.min.css"
import { Link, useHistory, useLocation } from "react-router-dom"
import useCloudinary from "components/hooks/useCloudinaryImage"
import { useWindowSize } from "react-use"

SwiperCore.use([Navigation, Pagination])

const Intro1 = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const { banner, loading } = useSelector(state => ({
    banner: state.Banner.banners,
    loading: state.Banner.loading,
  }))

  useEffect(() => {
    dispatch(getBanners())
  }, [dispatch])
  const { width, height } = useWindowSize()

  const heroBanner = banner?.mainBanners

  // const cat = catName?.split("?")[1]

  const selectCategory = (e, catName) => {
    const removeSpace = catName?.replace(/ /g, "-")
    e.preventDefault()

    if (catName === "all") {
      history.push({
        pathname: "/product",
      })
    } else {
      history.push({
        pathname: "/product",
        query: {
          ...location.query,
          cat: removeSpace,
          isProduct: false,
        },
      })
    }
  }

  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={0}
        pagination={true}
        navigation={{
          prevEl: ".custom_prev_i1",
          nextEl: ".custom_next_i1",
        }}
        className={`hero-slider-1 style-4 dot-style-1 dot-style-1-position-1 ${
          heroBanner?.length >= 2 ? "" : "onlyOneBanner"
        }`}
      >
        {loading ? (
          <SwiperSlide>
            <BannerLoader />
          </SwiperSlide>
        ) : (
          <>
            {map(heroBanner, (ban, key) => (
              <SwiperSlide key={key}>
                <div
                  onClick={e => selectCategory(e, ban?.link?.split("?")[1])}
                  className="single-hero-slider single-animation-wrap"
                  style={{
                    backgroundImage: `url(${useCloudinary(
                      ban?.images[0]?.url,
                      `w_1600`
                    )})`,
                    cursor: "pointer",
                  }}
                >
                  <div className="slider-content"></div>
                </div>
              </SwiperSlide>
            ))}
          </>
        )}
      </Swiper>

      <div className="slider-arrow hero-slider-1-arrow">
        <span className="slider-btn slider-prev slick-arrow custom_prev_i1">
          <i className="fi-rs-angle-left"></i>
        </span>
        <span className="slider-btn slider-next slick-arrow custom_next_i1">
          <i className="fi-rs-angle-right"></i>
        </span>
      </div>
    </>
  )
}

export default Intro1
