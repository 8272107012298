import React from "react"
import PropTypes from "prop-types"
import { Modal, ModalBody } from "reactstrap"
import CheckoutItem from "./checkoutItem"
import { Link } from "react-router-dom"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { clearError } from "store/actions"

const OrderItemPopup = ({
  onConfirmClick,
  onCloseClick,
  show,
  loading,
  data,
}) => {
  const { handleCartItem, error } = data

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(clearError())
  }, [])

  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      centered={true}
      style={deleteModalStyle}
      className="delete-modal"
    >
      <ModalBody style={deleteModalBodyStyle} className="px-3">
        <div>
          <div className="px-4 pt-3">
            <h6>Order Items</h6>
            <p
              className="font-size-13 text-danger mt-2"
              style={{ lineHeight: "15px" }}
            >
              {error}
            </p>
          </div>
          <div className="border p-1 m-3 cart-totals">
            <div className="payment_method">
              <div className="table-responsive order_table table-border-tr-none">
                {handleCartItem?.length > 0 ? (
                  <table className={"table no-border"}>
                    <tbody>
                      {handleCartItem?.map((item, i) => (
                        <CheckoutItem
                          item={item}
                          key={i}
                          isSmall={false}
                          isModal={true}
                        />
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div>
                    <p className="font-size-14">No order items found, </p>
                    <p className="font-size-14">
                      {" "}
                      “Explore our wide selection and find something you like”
                    </p>
                    <Link to={"/products"} className="btn btn-sm mt-3">
                      Shop now
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

OrderItemPopup.propTypes = {
  onCloseClick: PropTypes.func,
  onConfirmClick: PropTypes.func,
  show: PropTypes.bool,
  loading: PropTypes.bool,
  data: PropTypes.any,
}

export default OrderItemPopup

const deleteModalStyle = {
  width: 300,
}
const deleteModalBodyStyle = {
  minHeight: 300,
}
