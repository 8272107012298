import { connect, useSelector } from "react-redux"
import React from "react"
import SwiperCore, { Navigation, Thumbs } from "swiper"

import "swiper/swiper-bundle.min.css"
import "swiper/swiper.min.css"
import { Link, useHistory, useLocation } from "react-router-dom"
import { map } from "lodash"
import PropTypes from "prop-types"
import { Swiper, SwiperSlide } from "swiper/react"

import { ImCart } from "react-icons/im"

import { useLangSwitchquery } from "components/hooks/useLanguageSwicth"
import RotatingGallery from "components/Common/loaders/RotatingGallery"
import useCloudinary from "components/hooks/useCloudinaryImage"

SwiperCore.use([Navigation, Thumbs])

const CategoryProduct = ({ className, showCount }) => {
  //hooks
  const history = useHistory()
  const location = useLocation()
  const language = useLangSwitchquery()

  //state
  const query = location?.query

  const { allCategories, loading } = useSelector(state => ({
    allCategories: state.Classification.categories,
    loading: state.Classification.loading,
  }))

  const handleCheckBox = (e, category) => {
    e.preventDefault()
    if (category === "all") {
      history.push({
        pathname: "/product",
        query: {
          ...location.query,
          cat: "",
          prod: category,
          isProduct: true,
          coll: "",
          subCat: false,
        },
      })
    } else {
      history.push({
        pathname: "/product",
        query: {
          ...location.query,
          cat: category?.replace(/ /g, "-"),
          prod: "",
          isProduct: false,
          coll: "",
          subCat: false,
        },
        search: category?.replace(/ /g, "-"),
      })
    }
  }

  return (
    <>
      {loading ? (
        <>
          <RotatingGallery />
        </>
      ) : (
        <Swiper
          style={{
            "--swiper-navigation-color": "#fff",
            "--swiper-pagination-color": "#fff",
          }}
          spaceBetween={10}
          freeMode={true}
          slidesPerView={"auto"}
          navigation={true}
          className="mySwiper2 myNewArrow"
        >
          <SwiperSlide
            onClick={e => handleCheckBox(e, "all")}
            style={{ width: "fit-content" }}
          >
            <ul className={className}>
              <li className={query?.prod === "all" ? "active_tag" : ""}>
                <Link to={"#!"}>
                  <ImCart className="mx-2" style={{ fontSize: "14px" }} />
                  <span>All</span>
                </Link>
              </li>
            </ul>
          </SwiperSlide>

          {map(allCategories, (cat, key) => (
            <SwiperSlide
              key={key}
              onClick={e => handleCheckBox(e, cat.primaryLang?.name)}
              style={{ width: "fit-content" }}
            >
              <ul className={className}>
                <li
                  className={`${
                    query?.cat?.replace(/-/g, " ") === cat.primaryLang?.name
                      ? "active_tag"
                      : ""
                  } `}
                >
                  <Link to={"#!"}>
                    {cat?.logoImages[0]?.url ? (
                      <img
                        src={useCloudinary(
                          cat?.logoImages[0]?.url,
                          "c_thumb,w_100"
                        )}
                        className="fit-contain"
                        height="100%"
                        width={"100%"}
                      />
                    ) : (
                      <ImCart className="mx-2" style={{ fontSize: "14px" }} />
                    )}

                    <span style={{ whiteSpace: "nowrap" }}>
                      {language === "Primary"
                        ? cat.primaryLang?.name
                        : cat.secondaryLang?.name}
                    </span>
                  </Link>
                </li>
              </ul>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </>
  )
}

export default connect(null, {})(CategoryProduct)

CategoryProduct.propTypes = {
  className: PropTypes.string,
  showCount: PropTypes.bool,
}
