import React, { useEffect, useState } from "react"

import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import { FaSpinner } from "react-icons/fa"

import { map } from "lodash"
import { useLangSwitchquery } from "components/hooks/useLanguageSwicth"
import usePrice from "components/hooks/usePrice"
import { returnOrder } from "store/actions"
import { Link } from "react-router-dom"

const ReturnOrderPopup = ({ isShow, setIsShow }) => {
  const dispatch = useDispatch()
  const language = useLangSwitchquery()
  const { currencyCode } = usePrice()

  const { order, loading, shipping } = useSelector(state => ({
    order: state.Cart.order,
    loading: state.Cart.orderLoad,
    shipping: state.SiteSettings.siteSettings.shipping,
  }))

  const ReturnDuration = shipping?.returnDuration

  const orderItems = order?.orderItems

  const [selectedItemIds, setSelectedItemIds] = useState([])

  const handleClose = () => {
    setIsShow({ ...isShow, status: false })
  }

  const handleChangeCheckbox = (e, item) => {
    if (selectedItemIds?.includes(item?._id)) {
      const filteredItem = selectedItemIds?.filter(i => i !== item?._id)
      setSelectedItemIds(filteredItem)
    } else if (e.target.checked === true) {
      setSelectedItemIds([...selectedItemIds, item?._id])
    } else {
    }
  }

  const handleSubmitAddress = e => {
    e.preventDefault()
    if (selectedItemIds?.length >= 1) {
      const input = {
        id: order?._id,
        returnOrderItems: selectedItemIds,
      }
      dispatch(returnOrder(input))

      setIsShow({ ...isShow, status: false })
    } else {
      setIsShow({ ...isShow, status: false })
    }
  }

  const handleOrderItemStatus = status => {
    switch (status) {
      case "Returned":
      case "Return Processing":
        return true
      default:
        return false
    }
  }

  const returnableOrderItemFilter = orderItems?.filter(
    i => i?.isReturnViable === true
  )

  const retrunItemsCheckOne = returnableOrderItemFilter?.filter(i =>
    handleOrderItemStatus(i.orderItemStatus) ? "" : i
  )

  function getDifferenceInDays(date1, date2) {
    return Math.floor(
      Math.abs(date2.valueOf() - date1.valueOf()) / (1000 * 60 * 60 * 24)
    )
  }

  function handleReturnableDate(date) {
    const DateToNumber = new Date(date ? date : "").getTime()
    const DateNow = Date.now()
    return (
      getDifferenceInDays(DateToNumber, DateNow) <
      (ReturnDuration ? ReturnDuration : 0)
    )
  }

  const isReturnViableProduct = retrunItemsCheckOne?.filter(item =>
    handleReturnableDate(item.deliveredAt)
  )

  const retrunItems = isReturnViableProduct

  return (
    <>
      <div
        className={
          !isShow
            ? "modal fade custom-modal d-none"
            : "modal fade custom-modal  show d-block"
        }
      >
        <div className="modal-dialog">
          <div className="modal-content px-0 p-0">
            <button
              type="button"
              className="btn-close"
              onClick={handleClose}
            ></button>
            <div className="modal-body p-1 sm:p-4">
              <div
                className="deal custom-border align-items-center flex-col d-flex justify-content-center sm:p-0 p-4"
                style={{ minHeight: "fit-content" }}
              >
                <div
                  className="deal-top d-flex justify-between align-items-center h-50 pb-0 "
                  style={{
                    padding: "6px 20px 18px",
                  }}
                >
                  <div>
                    <h5 className="text-brand">Select an Item </h5>
                    {/* {ReturnDuration && product?.isReturnViable && (
                      <span>
                        Easy {ReturnDuration} days{" "}
                        <Link to={"/return-policy"}>return and exchanges </Link>
                        available!
                      </span>
                    )} */}
                  </div>
                </div>

                <div className="deal-content pt-4 px-4 sm:pt-4 pb-10">
                  {" "}
                  <form onSubmit={handleSubmitAddress}>
                    <div className="row">
                      {retrunItems?.length >= 1 ? (
                        <div className="form-group col-lg-6 col-md-6 col-sm-12">
                          {map(retrunItems, (item, key) => (
                            <div
                              className="d-flex align-items-center mb-2"
                              key={key}
                            >
                              <div>
                                <input
                                  checked={selectedItemIds?.includes(item?._id)}
                                  type="checkbox"
                                  name="isDefault"
                                  className="mx-1"
                                  id={item?._id}
                                  onChange={e => handleChangeCheckbox(e, item)}
                                  style={{ width: "20px", height: "20px" }}
                                />
                              </div>
                              <label
                                htmlFor={item?._id}
                                className="d-flex cursor-pointer"
                              >
                                <img
                                  src={item?.imageUrl}
                                  alt="item"
                                  height={"80px"}
                                  width={"80px"}
                                />
                                <div className="mx-2">
                                  <h5>
                                    {language === "Primary"
                                      ? item?.primaryLang?.name
                                      : item?.secondaryLang?.name}{" "}
                                    ({item?.varientValue} {item?.varientUnit})
                                  </h5>
                                  <p>
                                    {item?.quantity} x {currencyCode}
                                    {item?.price}
                                  </p>
                                </div>
                              </label>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <p>
                          No items found to return, please check your order item
                          status
                        </p>
                      )}
                    </div>
                    <div className="w-100 d-flex align-items-center justify-content-end">
                      <button
                        type="submit"
                        className="btn btn-fill-out btn-block btn-md w-25 sm:w-50 ml-auto mx-3 mt-30"
                      >
                        Submit
                        {loading && <FaSpinner className="spinner mx-1" />}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={
          !isShow ? "modal-backdrop fade d-none" : "modal-backdrop fade show"
        }
      ></div>
    </>
  )
}

export default ReturnOrderPopup

ReturnOrderPopup.propTypes = {
  isShow: PropTypes.bool,
  setIsShow: PropTypes.func,
  data: PropTypes.any,
}
