import CustomizedLoader from "components/Common/loaders/CustomizedLoader"
import CategoryProductSlider from "components/sliders/Featured"
import { map, range } from "lodash"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { getNestedCategory } from "store/actions"
import PropTypes from "prop-types"

function DailyFresh({ groupDetail }) {
  const dispatch = useDispatch()

  const { nestedCategoryProd, nestedLoad } = useSelector(state => ({
    nestedCategoryProd: state.Classification.nestedCategoryProd,
    nestedLoad: state.Classification.nestedLoad,
  }))

  useEffect(() => {
    dispatch(getNestedCategory(groupDetail?.categories[0]?._id, ""))
  }, [dispatch, groupDetail])

  const subCategories = nestedCategoryProd?.subCategories

  return (
    <section className="section-padding mb-4">
      <div className="container">
        <>
          <div className="section-title wow animate__animated animate__fadeIn">
            <h3 className="sm:font-size-16 section_head_h3">
              {groupDetail?.primaryLang?.name}nested_loop
            </h3>
          </div>

          <div className="row">
            <div className="col-lg-3 d-none d-lg-flex wow animate__animated animate__fadeIn">
              <div className="banner-img h-auto style-3">
                <div className="banner-text d-flex flex-col justify-center align-items-center h-100">
                  <h2 className="mb-100">
                    {groupDetail?.primaryLang?.description}
                  </h2>
                  <Link to="/categroies">
                    <span className="btn btn-xs text-white">
                      Explore More <i className="fi-rs-arrow-small-right"></i>
                    </span>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-lg-9 col-md-12 pl-0">
              <div
                className="tab-content wow fadeIn animated"
                id="myTabContent"
              >
                {nestedLoad ? (
                  <>
                    {map(range(3), item => (
                      <div className={"tab-pane fade show active"} key={item}>
                        <div className="carausel-4-columns-cover card-product-small arrow-center position-relative">
                          <CustomizedLoader
                            count={6}
                            mobileCount={3}
                            style={{ height: "180px" }}
                            innerClass="mx-1"
                          />
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    {map(subCategories?.slice(0, 4), (subCat, key) => (
                      <div className={"tab-pane fade show active"} key={key}>
                        <div className="carausel-4-columns-cover card-product-small arrow-center position-relative">
                          <CategoryProductSlider subCat={subCat} />
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      </div>
    </section>
  )
}

export default DailyFresh

DailyFresh.propTypes = {
  groupDetail: PropTypes.any,
}
