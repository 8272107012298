import React from "react"
import { useHistory, useLocation } from "react-router-dom"

import { useSelector } from "react-redux"
import { map } from "lodash"
import CustomizedLoader from "components/Common/loaders/CustomizedLoader"
import useCloudinary from "components/hooks/useCloudinaryImage"

const Banner5 = () => {
  const history = useHistory()
  const location = useLocation()

  const { banner, loading } = useSelector(state => ({
    banner: state.Banner.banners,
    loading: state.Banner.loading,
  }))

  const subBanners = banner?.subBanners

  const selectCategory = (e, catName) => {
    const removeSpace = catName?.replace(/ /g, "-")
    e.preventDefault()

    if (catName === "all") {
      history.push({
        pathname: "/product",
      })
    } else {
      history.push({
        pathname: "/product",
        query: {
          ...location.query,
          cat: removeSpace,
          isProduct: false,
        },
      })
    }
  }

  return (
    <>
      {loading ? (
        <>
          <CustomizedLoader
            count={3}
            mobileCount={1}
            style={{ height: "250px" }}
            innerClass="mx-1"
          />
        </>
      ) : (
        <>
          {map(subBanners, (ban, key) => (
            <div className="col-lg-4 col-md-6  m-auto" key={key}>
              {/* <Link to={`/collections/${ban?.link?.replace(/ /g, "-")}`}> */}
              <div
                onClick={e => selectCategory(e, ban?.link?.split("?")[1])}
                className="banner-img wow animate__animated md:mb-2 animate__fadeInUp w-100"
                style={{
                  height: "265px",
                  cursor: "pointer",
                }}
                data-wow-delay="0"
              >
                <img
                  src={useCloudinary(ban?.images[0]?.url, "q_auto:eco")}
                  alt=""
                  className="h-100 w-100 object-cover"
                  style={{ objectFit: "cover" }}
                />
                <div className="banner-text">
                  {/* <h4>
                Everyday Fresh & <br />
                Clean with Our
                <br />
                Products
              </h4> */}
                  {/* <Link to="/products">
                <span className="btn btn-xs text-white">
                Shop Now <i className="fi-rs-arrow-small-right"></i>
                </span>
              </Link> */}
                </div>
              </div>
              {/* </Link> */}
            </div>
          ))}
        </>
      )}
      {/* <div className="col-lg-4 d-md-none d-lg-flex">
        <div
          className="banner-img mb-sm-0 wow animate__animated animate__fadeInUp"
          data-wow-delay=".4s"
        >
          <img src={""} alt="" />
          <div className="banner-text">
            <h4>
              The best Organic <br />
              Products Online
            </h4>
            <Link to="/products">
              <span className="btn btn-xs text-white">
                Shop Now <i className="fi-rs-arrow-small-right"></i>
              </span>
            </Link>
          </div>
        </div>
      </div> */}
    </>
  )
}

export default Banner5
