import React, { useEffect, useState } from "react"
import "./preloader.css"
import { withRouter } from "react-router-dom"
import { LogoSm } from "assets/imgs/logos"

const PreLoader = () => {
  const [Loading, setLoading] = useState(true)
  useEffect(() => {
    const load = () =>
      window.addEventListener("load", event => {
        setTimeout(() => {
          setLoading(false)
        }, 2000)
      })
    return load
  }, [Loading])

  return (
    <>
      {Loading && (
        <>
          {
            <div className="preloaderBg" id="preloader">
              <div className="preloader">
                <img src={LogoSm} alt="logo" />
                <div className="preloader2"></div>
              </div>
            </div>
          }
        </>
      )}
    </>
  )
}

export default withRouter(PreLoader)
