import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { FaSpinner } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"
import usePrice from "components/hooks/usePrice"
import useLangSwitch from "components/hooks/useLanguageSwicth"
import useCartActions from "components/hooks/useCartAction"
import { BsExclamationCircle } from "react-icons/bs"
import { getLocalCart } from "store/actions"
import useCloudinary from "components/hooks/useCloudinaryImage"

const CartItem = ({ item }) => {
  const product = item?.product

  const dispatch = useDispatch()

  const language = useLangSwitch(product?.primaryLang, product?.secondaryLang)

  const { loading } = useSelector(state => ({
    loading: state.Cart.addOrRemoveLoad,
  }))
  const token = localStorage.getItem("idealeshope_token")

  const { currencyCode, oldPrice, returnText, price } = usePrice(product)

  const increaseQuantity = item => {
    const options = {
      product: item?.product?._id,
      varient: item?.varient,
      operation: "add",
    }
    console.log(item)
    useCartActions(options, dispatch, item?.product)
    if (!token) {
      dispatch(getLocalCart())
    }
  }

  const decreaseQuantity = item => {
    if (item?.quantity <= 1) {
      return
    } else {
      const options = {
        product: item?.product?._id,
        varient: item?.varient,
        operation: "substract",
      }
      useCartActions(options, dispatch, item?.product)
      if (!token) {
        dispatch(getLocalCart())
      }
    }
  }

  const deleteFromCart = item => {
    const options = {
      product: item?.product?._id,
      varient: item?.varient,
      operation: "substract",
      quantity: item?.quantity ?? 1,
    }
    useCartActions(options, dispatch, item?.product)
    if (!token) {
      dispatch(getLocalCart())
    }
  }

  const currentVarient = item?.product?.varients?.filter(
    i => i._id === item?.varient
  )[0]

  const handleStockText = () => {
    if (item?.isOutOfStock === true) {
      if (currentVarient?.stock <= 0) {
        return "Out of stock"
      } else if (item?.quantity > currentVarient?.stock) {
        return "Only " + currentVarient?.stock + " left"
      }
    } else return ""
  }
  const removeSpace = language?.name?.replace(/ /g, "-")

  return (
    <>
      {item?.product !== null && (
        <tr className="border-none border-b-4  mt-1 sm:p-2 sm:d-flex sm:flex-col">
          <td className="image product-thumbnail cart_image">
            <Link to={`/products/${removeSpace}`}>
              <img src={useCloudinary(product.images[0]?.url, "h_350,w_350")} />
            </Link>
          </td>

          <td className="product-des product-name px-4 ">
            <h6
              className="product-name"
              style={{
                whiteSpace: "break-spaces",
                width: "200px",
                fontWeight: "400",
              }}
            >
              <Link to={`/products/${removeSpace}`} className="text-black">
                {language?.name}
                {item?.varientValue && (
                  <span>
                    {" "}
                    ({item?.varientValue}&nbsp;
                    {item?.varientUnit})
                  </span>
                )}
              </Link>
            </h6>
            {/* {item?.isReturnViable && (
            <p className="d-flex align-items-center mt-2">
              <BsExclamationCircle className="text-green me-1" /> {returnText}
            </p>
          )} */}
          </td>
          <td className="price sm:display-between" data-title="Price">
            <div className="d-flex align-items-end justify-content-start">
              <p className="old-price mr-1">
                <del>{oldPrice}</del>
              </p>
              {token ? (
                <h5 className="text-black" style={{ fontWeight: "500" }}>
                  {currencyCode} {item?.price ?? price}
                </h5>
              ) : (
                <h5 className="text-black" style={{ fontWeight: "500" }}>
                  {/* //localStorage */}
                  {currencyCode}{" "}
                  {item?.offerPrice ? item?.offerPrice : item?.price ?? price}
                </h5>
              )}
            </div>
          </td>
          <td
            className="text-start detail-info sm:display-between"
            data-title="Quantity"
          >
            <div className="detail-extralink ">
              <div className="detail-qty border radius m-0 ">
                <a onClick={() => decreaseQuantity(item)} className="qty-down">
                  <i className="fi-rs-angle-small-down"></i>
                </a>
                <span className="qty-val d-flex align-items-center">
                  {item.quantity}
                  {loading && <FaSpinner className="mx-2 spinner" />}
                </span>
                <a onClick={() => increaseQuantity(item)} className="qty-up">
                  <i className="fi-rs-angle-small-up"></i>
                </a>
              </div>
              {handleStockText() && (
                <p className="text-danger d-flex align-items-center mt-2">
                  {" "}
                  <BsExclamationCircle className="text-danger me-2" />{" "}
                  {handleStockText()}
                </p>
              )}
            </div>
          </td>
          <td
            className="text-right px-2 sm:px-0 sm:display-between"
            data-title="Subtotal"
          >
            {token ? (
              <h5 className="text-brand " style={{ fontWeight: "500" }}>
                {currencyCode} {item.quantity * (item?.price ?? price)}
              </h5>
            ) : (
              <h5 className="text-black " style={{ fontWeight: "500" }}>
                {/* //localStorage */}
                {currencyCode}{" "}
                {item.quantity *
                  (item?.offerPrice ? item?.offerPrice : item?.price ?? price)}
              </h5>
            )}
          </td>
          <td
            className="action px-2 sm:px-0 sm:display-between"
            data-title="Remove"
          >
            <a
              onClick={() => deleteFromCart(item)}
              className="text-muted d-flex align-items-center justify-content-center"
            >
              <i className="fi-rs-trash"></i>
            </a>
          </td>
        </tr>
      )}
    </>
  )
}

export default CartItem

CartItem.propTypes = {
  item: PropTypes.any,
}

{
  /* <tr className="border-none border-b-4">
  <td></td>
  <td>
    {item?.isReturnViable && (
      <p className="d-flex align-items-center">
        <BsExclamationCircle className="text-green mx-2" /> {returnText}
      </p>
    )}
  </td>
  <td className="">
    {handleStockText() && (
      <p className="text-danger d-flex align-items-center">
        {" "}
        <BsExclamationCircle className="text-danger mx-2" />{" "}
        {handleStockText()}
      </p>
    )}
  </td>
</tr> */
}
