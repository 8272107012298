import { useState } from "react"
import useClickOutside from "util/outsideClick"
import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"

import { MainLogo } from "assets/imgs/logos"
import { year } from "constants/layout"
import { useDispatch, useSelector } from "react-redux"
import { FaFacebookF, FaLinkedinIn, FaTiktok, FaWhatsapp } from "react-icons/fa"
import { RiSnapchatLine } from "react-icons/ri"
import { GrInstagram } from "react-icons/gr"
import { logoutUser } from "store/actions"
import { useHistory } from "react-router-dom"

const MobileMenu = ({ isToggled, toggleClick }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [isActive, setIsActive] = useState({
    status: false,
    key: "",
  })

  // const handleToggle = key => {
  //   if (isActive.key === key) {
  //     setIsActive({
  //       status: false,
  //     })
  //   } else {
  //     setIsActive({
  //       status: true,
  //       key,
  //     })
  //   }
  // }

  let domNode = useClickOutside(() => {
    setIsActive({
      status: false,
    })
  })

  const { company, socialLinks } = useSelector(state => ({
    company: state?.SiteSettings.siteSettings?.company,
    socialLinks: state.SiteSettings.siteSettings.socialLinks,
  }))

  const handleLogout = () => {
    dispatch(logoutUser(history))
    toggleClick()
  }

  return (
    <>
      <div
        className={
          isToggled
            ? "mobile-header-active mobile-header-wrapper-style sidebar-visible"
            : "mobile-header-active mobile-header-wrapper-style"
        }
      >
        <div className="mobile-header-wrapper-inner">
          <div className="mobile-header-top">
            <div className="mobile-header-logo">
              <Link to="/" className="" style={{ width: "150px" }}>
                <img
                  src={MainLogo}
                  alt="logo"
                  height={"30px"}
                  style={{ width: "160px" }}
                />
              </Link>
            </div>
            <div className="mobile-menu-close close-style-wrap close-style-position-inherit">
              <button
                className="close-style search-close"
                onClick={toggleClick}
              >
                <i className="icon-top"></i>
                <i className="icon-bottom"></i>
              </button>
            </div>
          </div>
          <div className="mobile-header-content-area">
            <div>
              <div className="mobile-menu-wrap mobile-header-border">
                <div className="main-categori-wrap mobile-header-border">
                  {/* <div className="categori-dropdown-wrap categori-dropdown-active-small">
                    <ul>
                      <li>
                        <Link to="/shop-grid-right">
                          <i className="evara-font-dress"></i>
                          Women&apos;s Clothing
                        </Link>
                      </li>
                    </ul>
                  </div> */}
                </div>

                <nav>
                  <ul className="mobile-menu" ref={domNode}>
                    {/* <li
                      className={
                        isActive.key == 1
                          ? "menu-item-has-children active"
                          : "menu-item-has-children"
                      }
                    >to="/user-account"
                      <Link to="/">Home</Link>
                    </li> */}
                    <li>
                      <Link
                        to={{
                          pathname: "/user-account",
                          state: { index: 2 },
                        }}
                      >
                        My Orders
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={{
                          pathname: "/user-account",
                          state: { index: 2 },
                        }}
                      >
                        Track Order
                      </Link>
                    </li>
                    {/* <li
                      className={
                        isActive.key == 2
                          ? "menu-item-has-children active"
                          : "menu-item-has-children"
                      }
                    >
                      <Link to="/collections">Collections</Link>
                    </li> */}
                    <li>
                      <Link to="categroies">Categories</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                    <li>
                      <Link to="/return-policy">Return Policy</Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/terms">Terms</Link>
                    </li>
                    <li>
                      <Link to="#" onClick={handleLogout}>
                        logout
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>

          <div className="mobile-header-top menu_footer">
            <div className="w-100 mb-0">
              <p className="font-normal mb-0">
                {company?.contactDetails?.email}
              </p>
              <p className="font-normal mb-0 text-brand">
                {company?.contactDetails?.mobile}
              </p>
              <div className="d-flex menu_footer_follow">
                {socialLinks?.facebook && (
                  <a
                    href={socialLinks?.facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="d-flex align-items-center justify-content-center"
                  >
                    {" "}
                    <FaFacebookF />
                  </a>
                )}
                {socialLinks?.instagram && (
                  <a
                    href={socialLinks?.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <GrInstagram />
                  </a>
                )}

                {socialLinks?.linkedin && (
                  <a
                    href={socialLinks?.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <FaLinkedinIn />
                  </a>
                )}

                {socialLinks?.whatsApp && (
                  <a
                    href={socialLinks?.whatsApp}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <FaWhatsapp />
                  </a>
                )}
                {socialLinks?.snapchat && (
                  <a
                    href={socialLinks?.snapchat}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <RiSnapchatLine />
                  </a>
                )}
                {socialLinks?.tiktok && (
                  <a
                    href={socialLinks?.tiktok}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <FaTiktok />
                  </a>
                )}
              </div>
            </div>
            <div className="menu_footer_sub">
              <p className="font-sm mb-0">
                Designed by &nbsp;
                <a href="https://osperb.com" title="website development firm">
                  <span to="/" className="text-osperb">
                    osperb
                  </span>
                  <br />
                </a>
                &copy; {year}{" "}
                <span className="text-brand">{company?.name}</span> all rights
                reserved
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MobileMenu

MobileMenu.propTypes = {
  isToggled: PropTypes.any,
  toggleClick: PropTypes.any,
}

// <li
//                     className={
//                       isActive.key == 2
//                         ? "menu-item-has-children active"
//                         : "menu-item-has-children"
//                     }
//                   >
//                     <span
//                       className="menu-expand"
//                       onClick={() => handleToggle(2)}
//                     >
//                       <i className="fi-rs-angle-small-down"></i>
//                     </span>
//                     <Link to="/product">shop</Link>
//                     <ul className={isActive.key == 2 ? "dropdown" : "d-none"}>
//                       <li>
//                         <Link to="/shop-grid-right">
//                           Shop Grid – Right Sidebar
//                         </Link>
//                       </li>
//                       <li>
//                         <Link to="/shop-grid-left">
//                           Shop Grid – Left Sidebar
//                         </Link>
//                       </li>

//                     </ul>
//                   </li>
