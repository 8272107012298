import React from "react"
import PropTyeps from "prop-types"
import { useWindowSize } from "react-use"
import SwiperCore, { Navigation, Keyboard, Mousewheel } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.min.css"
import "swiper/swiper.scss"
import ProductCardOne from "components/ecommerce/productCards/ProductCardOne"

SwiperCore.use([Navigation, Keyboard, Mousewheel])

function SliderList({ products }) {
  const { width } = useWindowSize()

  const handleWindowSizeVsSliderPreview = () => {
    if (width < 480) {
      return 2
    } else if (width < 640) {
      return 2.5
    } else if (width < 768) {
      return 3.5
    } else return 5.5
  }

  return (
    <>
      <div className="carausel-4-columns-cover card-product-small arrow-center position-relative">
        <Swiper
          spaceBetween={8}
          slidesPerView={handleWindowSizeVsSliderPreview()}
          grid={{
            rows: 2,
          }}
          keyboard={{ enabled: true }}
          direction="horizontal"
          // mousewheel={true}
          navigation={{
            prevEl: ".custom_prev_f",
            nextEl: ".custom_next_f",
          }}
          autoplay={true}
          speed={600}
          className=""
          breakpoints={{
            480: {
              slidesPerView: 2,
            },
            640: {
              slidesPerView: 2.5,
            },
            768: {
              slidesPerView: 3.5,
            },
            1024: {
              slidesPerView: 5.5,
            },
          }}
        >
          {products?.map((product, i) => (
            <SwiperSlide key={i}>
              <ProductCardOne product={product} />
            </SwiperSlide>
          ))}
          <div className="slider-arrow slider-arrow-2 carausel-4-columns-arrow">
            <span className="slider-btn slider-prev slick-arrow custom_prev_f ">
              <i className="fi-rs-arrow-small-left"></i>
            </span>
            <span className="slider-btn slider-next slick-arrow custom_next_f">
              <i className="fi-rs-arrow-small-right"></i>
            </span>
          </div>
        </Swiper>
      </div>
    </>
  )
}

export default SliderList

SliderList.propTypes = {
  products: PropTyeps.array,
}
