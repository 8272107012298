import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import TableLoader from "components/Common/loaders/TableLoader"
import { useDispatch, useSelector } from "react-redux"
import { getUserReviews } from "store/actions"
import InfiniteScroll from "react-infinite-scroll-component"
import ProductListLoader from "components/Common/loaders/ProductListLoader"
import CustomizedLoader from "components/Common/loaders/CustomizedLoader"
import { map } from "lodash"
import { useWindowSize } from "react-use"
import ReviewedItem from "./ReviewItem"

function MyReview({ activeIndex }) {
    const dispatch = useDispatch()
    const { width } = useWindowSize()
    const { loading, userReview } = useSelector(state => ({
        userReview: state.Cart.userReview,
        loading: state.Cart.loading,
    }))

    //pagination
    const limit = 10
    const allpages = userReview?.total / limit
    let [pages, setPages] = useState(1)
    const [allReviews, setAllReviews] = useState([])

    const reviews = userReview?.reviews
    useEffect(() => {
        if (reviews) {
            setAllReviews([...allReviews, ...reviews])
        }
    }, [reviews, pages])

    const ids = allReviews?.map(o => o._id)

    const filteredFinalReview = allReviews?.filter(
        ({ _id }, index) => !ids.includes(_id, index + 1)
    )

    const HandlefetchNextPage = () => {
        if (pages < allpages) {
            setPages(pages + 1)
        }
    }

    useEffect(() => {
        if (activeIndex === 2) {
            dispatch(getUserReviews(pages, limit))
        }
    }, [dispatch, pages, limit, activeIndex])



    return (
        <>
            <div
                className={
                    activeIndex === 2 ? "tab-pane fade active show" : "tab-pane fade "
                }
            >
                <InfiniteScroll
                    dataLength={filteredFinalReview?.length ?? 0}
                    next={HandlefetchNextPage}
                    hasMore={true}
                    loader={loading && <ProductListLoader key={0} />}
                >
                    <div className="card-body sm:px-0">
                        {loading ? (
                            <>
                                {width >= 480 ? (
                                    <TableLoader />
                                ) : (
                                    <CustomizedLoader
                                        mobileCount={4}
                                        containerClass={"flex row"}
                                        style={{ height: "150px" }}
                                    />
                                )}
                            </>
                        ) : (
                            <>
                                <div className="row">
                                    {map(filteredFinalReview, (order, key) => (
                                        <ReviewedItem key={key} item={order} />
                                    ))}
                                </div>
                            </>
                        )}
                    </div>
                </InfiniteScroll>

            </div>
        </>
    )


}

export default MyReview

MyReview.propTypes = {
    activeIndex: PropTypes.any,
}
