import ProductCardOne from "components/ecommerce/productCards/ProductCardOne"
import React from "react"
import SwiperCore, { Navigation, Keyboard, Mousewheel } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.min.css"
import "swiper/swiper.scss"

SwiperCore.use([Navigation, Keyboard, Mousewheel])

import { useWindowSize } from "react-use"
import PropTypes from "prop-types"

function SliderList2({ products }) {
  const { width } = useWindowSize()

  const handleWindowSizeVsSliderPreview = () => {
    if (width < 480) {
      return 2
    } else if (width < 640) {
      return 2.2
    } else if (width < 768) {
      return 3.2
    } else return 4.2
  }

  return (
    <div className="carausel-4-columns-cover card-product-small arrow-center position-relative w-70% sm:w-100-pre flex-1 ml-auto">
      <Swiper
        spaceBetween={20}
        slidesPerView={handleWindowSizeVsSliderPreview()}
        grid={{
          rows: 2,
        }}
        keyboard={{ enabled: true }}
        direction="horizontal"
        // mousewheel={true}
        navigation={{
          prevEl: ".custom_prev_f",
          nextEl: ".custom_next_f",
        }}
        // autoplay={true}
        speed={200}
        className=""
        breakpoints={{
          480: {
            slidesPerView: 2,
          },
          640: {
            slidesPerView: 2.2,
          },
          768: {
            slidesPerView: 3.2,
          },
          1024: {
            slidesPerView: 4.2,
          },
        }}
      >
        {products?.map((product, i) => (
          <SwiperSlide key={i}>
            <ProductCardOne className="" product={product} />
          </SwiperSlide>
        ))}
        <div className="slider-arrow slider-arrow-2 carausel-4-columns-arrow">
          <span className="slider-btn slider-prev slick-arrow custom_prev_f ">
            <i className="fi-rs-arrow-small-left"></i>
          </span>
          <span className="slider-btn slider-next slick-arrow custom_next_f">
            <i className="fi-rs-arrow-small-right"></i>
          </span>
        </div>
      </Swiper>
    </div>
  )
}

export default SliderList2

SliderList2.propTypes = {
  products: PropTypes.any,
}
