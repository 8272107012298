/* SITE_SETTINGS */
export const GET_SITE_SETTINGS = "GET_SITE_SETTINGS"
export const GET_SITE_SETTINGS_SUCCESS = "GET_SITE_SETTINGS_SUCCESS"
export const GET_SITE_SETTINGS_FAIL = "GET_SITE_SETTINGS_FAIL"

export const GET_PAYMENT_KEY = "GET_PAYMENT_KEY"
export const GET_PAYMENT_KEY_SUCCESS = "GET_PAYMENT_KEY_SUCCESS"
export const GET_PAYMENT_KEY_FAIL = "GET_PAYMENT_KEY_KEY"

export const OTHER_PAYMENT_OPTIONS = "OTHER_PAYMENT_OPTIONS"
export const OTHER_PAYMENT_OPTIONS_SUCCESS = "OTHER_PAYMENT_OPTIONS_SUCCESS"
export const OTHER_PAYMENT_OPTIONS_FAIL = "OTHER_PAYMENT_OPTIONS_KEY"
