import Layout from "components/layout/Layout"
import React, { useEffect, useState } from "react"
import OtpInput from "react-otp-input"
import { useDispatch, useSelector } from "react-redux"
import {
  bulkCart,
  getLocalCart,
  getUserCart,
  hideMobileNavAction,
  loginUserMobile,
  loginUserOtp,
  showMobileNavAction,
} from "store/actions"
import usePrice from "components/hooks/usePrice"
import useLangSwitch from "components/hooks/useLanguageSwicth"
import SmallCardLoader from "components/Common/loaders/SmallCardLoader"
import { FaSpinner } from "react-icons/fa"
import CheckoutItem from "./checkoutItem"
import { useLocalCartPirces } from "components/hooks/useCartAction"
import FilesLoad from "assets/imgs/theme/transfer-files.gif"
import { useHistory } from "react-router-dom"
import { useWindowSize } from "react-use"

const CheckoutLogin = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { width } = useWindowSize()

  const token = localStorage.getItem("idealeshope_token")

  const {
    userCart,
    loading,
    bulkLoad,
    cartItems,
    specialMessages,
    localCart,
    userLoading,
    user,
    otp,
  } = useSelector(state => ({
    user: state.Login.user,
    userLoading: state.Login.loading,
    otp: state.Login.otp,
    userCart: state.Cart.userCart,
    loading: state.Cart.loading,
    bulkLoad: state.Cart.bulkLoad,
    cartItems: state.Cart.cartItems,
    localCart: state.Cart.localCart,
    specialMessages: state.SiteSettings.siteSettings.specialMessages,
  }))

  const [type, setType] = useState("login")
  const [phoneNumber, setPhoneNumber] = useState(0)

  const [otpText, setOtpText] = useState()

  const handleOtpChnages = v => {
    setOtpText(v)
  }

  useEffect(() => {
    dispatch(getUserCart())
  }, [dispatch])

  useEffect(() => {
    if (user?.userId && otpText?.length === 4) {
      dispatch(loginUserOtp({ otp: otpText, userId: user?.userId }))
    }
  }, [otpText])

  useEffect(() => {
    if (token && localCart?.length >= 1) {
      const localCartFiltered = localCart?.filter(i => i.quantity !== 0)

      const modifiedLocalCart = localCartFiltered?.map(item => ({
        product: item?.product?._id,
        varient: item?.varient,
        operation: item?.operation,
        quantity: item?.quantity,
      }))
      if (modifiedLocalCart) {
        dispatch(bulkCart(modifiedLocalCart, history))
      }
    }
  }, [token])

  useEffect(() => {
    if (!token) {
      dispatch(getLocalCart())
    }
  }, [])

  useEffect(() => {
    if (user?.userId) {
      setType("isOTP")
    } else {
      setType("login")
    }
  }, [user])

  const { currencyCode } = usePrice()

  const language = useLangSwitch(
    specialMessages?.primaryLang,
    specialMessages?.secondaryLang
  )

  const handleCartItem = () => {
    if (cartItems?.length >= 1) {
      return cartItems
    } else {
      return localCart?.filter(i => i.quantity !== 0)
    }
  }

  const { itemTotal } = useLocalCartPirces()

  const handleMobileLogin = e => {
    e.preventDefault()
    dispatch(loginUserMobile({ phone: phoneNumber }, "login"))
  }

  const resendOtp = () => {
    dispatch(loginUserMobile({ phone: phoneNumber }, "login"))
  }

  return (
    <>
      <Layout parent="Home" sub="Shop" subChild="Checkout">
        <section className="mt-50 mb-50 myCheckout">
          <div className="container px-5 ">
            <div className="row">
              <div className="col-lg-6 m-auto inputField">
                <div className="row mb-50">
                  <div className="mb-sm-15 mb-lg-0 mb-md-3 ">
                    <div className="panel-collapse login_form" id="">
                      {bulkLoad && token ? (
                        <div className="position-relative m-auto bulkloading">
                          <p
                            className="position-absolute "
                            style={{ bottom: "45px", right: "35px" }}
                          >
                            {" "}
                            fetching your cart ...
                          </p>

                          <img
                            className="h-100 w-100"
                            src={FilesLoad}
                            alt="sync"
                          />
                        </div>
                      ) : (
                        <>
                          {type === "isOTP" ? (
                            <>
                              <div className="panel-body mt-0">
                                <div className="deal-top">
                                  <h6>Enter OTP</h6>
                                  <p className="mb-0 font-sm">
                                    code is sent to {phoneNumber}
                                  </p>
                                </div>
                                <div className="deal-content my-3 mb-0 detail-info">
                                  <div className="clearfix product-price-cover">
                                    <form method="post" className="">
                                      <div className="wrapper_otp ">
                                        <div className="d-flex align-items-end flex-wrap mobileOtpinput">
                                          <OtpInput
                                            value={otpText}
                                            onChange={v => handleOtpChnages(v)}
                                            numInputs={4}
                                            isInputNum={true}
                                            inputStyle={"otp_input"}
                                            containerStyle={
                                              "otp_container otp_cont_2"
                                            }
                                            shouldAutoFocus={true}
                                            focusStyle={"otp_focus"}
                                          />
                                          {otp?.message ? (
                                            <p className="text-end text-info mb-0">
                                              {otp?.message}
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                </div>

                                <div className="d-flex otpButton align-items-center justify-content-start mt-4">
                                  <button
                                    type="submit"
                                    className="btn btn-heading bg-green btn-block px-5 mr-auto w-50"
                                  >
                                    Continue
                                    {userLoading && (
                                      <FaSpinner className="mx-2 spinner" />
                                    )}
                                  </button>
                                </div>
                                <p className="font-sm mt-3">
                                  didn&#39;t recieve OTP ?{" "}
                                  <u
                                    className="text-black"
                                    onClick={resendOtp}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {" "}
                                    Re-send again
                                  </u>
                                </p>
                                <p className="font-sm">
                                  or change
                                  <u
                                    className="text-black"
                                    onClick={() => setType("login")}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {" "}
                                    Mobile number{" "}
                                  </u>
                                </p>
                              </div>
                            </>
                          ) : (
                            <div className="panel-body mt-0">
                              <h6 className="mb-3 font-md">
                                Enter your mobile number
                              </h6>
                              <p className="font-xs mb-40 sm:mb-2">
                                You&#39;ll recieve 4 digit code to verify next.
                              </p>

                              <form
                                method="post"
                                className="position-relative"
                                onSubmit={e => handleMobileLogin(e)}
                              >
                                <div className="form-group mb-2 d-flex align-items-center justify-end mobileForm">
                                  <span className="text-size-20 mr-1">+91</span>
                                  <input
                                    type="phone"
                                    required={true}
                                    minLength={10}
                                    maxLength={10}
                                    name="phone"
                                    placeholder="Phone Number"
                                    onChange={e =>
                                      setPhoneNumber(e.target.value)
                                    }
                                    // onClick={() => dispatch(hideMobileNavAction())}
                                  />
                                </div>
                                {width > 480 && (
                                  <p className="mb-20 font-xs text-end">
                                    By continuing, I agree to the{" "}
                                    <a href="#" className="white-space-nowrap">
                                      Terms of Use
                                    </a>{" "}
                                    &
                                    <a href="#" className="white-space-nowrap">
                                      {" "}
                                      Privacy Policy
                                    </a>
                                  </p>
                                )}
                                <div className="form-group d-flex align-items-center justify-end mt-4">
                                  <button
                                    type="submit"
                                    className="btn btn-heading btn-block bg-green px-5"
                                    name="login"
                                  >
                                    Continue
                                    {userLoading && (
                                      <FaSpinner className="mx-2 spinner" />
                                    )}
                                  </button>
                                </div>
                                {width < 480 && (
                                  <p className="mb-20 font-xs text-end mobile_text">
                                    By continuing, I agree to the{" "}
                                    <a href="#" className="white-space-nowrap">
                                      Terms of Use
                                    </a>{" "}
                                    &
                                    <a href="#" className="white-space-nowrap">
                                      {" "}
                                      Privacy Policy
                                    </a>
                                  </p>
                                )}
                              </form>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default CheckoutLogin
