import React, { useState } from "react"
import SwiperCore, { Navigation, Keyboard, Mousewheel } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { useWindowSize } from "react-use"
import useLangSwitch from "components/hooks/useLanguageSwicth"
import ProductCardTwo from "../ecommerce/productCards/ProductCardTwo"

import "swiper/swiper-bundle.min.css"
import "swiper/swiper.scss"
import useCloudinary from "components/hooks/useCloudinaryImage"

SwiperCore.use([Navigation, Keyboard, Mousewheel])

const CategoryProductSlider = ({ subCat }) => {
  const { width } = useWindowSize()

  const [first, setFirst] = useState(false)

  const handleWindowSizeVsSliderPreview = () => {
    if (width < 480) {
      return 2.5
    } else if (width < 640) {
      return 3.5
    } else if (width < 768) {
      return 4.5
    } else return 5.5
  }

  const language = useLangSwitch(subCat?.primaryLang, subCat?.secondaryLang)

  const removeSpace = language?.name?.replace(/ /g, "-")

  return (
    <>
      <Swiper
        spaceBetween={5}
        slidesPerView={handleWindowSizeVsSliderPreview()}
        grid={{
          rows: 2,
        }}
        keyboard={{ enabled: true }}
        direction="horizontal"
        navigation={{
          prevEl: ".custom_prev_f",
          nextEl: ".custom_next_f",
        }}
        speed={800}
        onActiveIndexChange={e => e.activeIndex === 0 && setFirst(false)}
        // autoplay={true}
        className="custom-class text-center"
        breakpoints={{
          480: {
            slidesPerView: 2.5,
          },
          640: {
            slidesPerView: 3.5,
          },
          768: {
            slidesPerView: 4.5,
          },
          1024: {
            slidesPerView: 5.5,
          },
        }}
      >
        <SwiperSlide className="mySlider">
          <div className="p-2 bg-pista mx-2 border-radius-10 h-95 d-flex flex-col ">
            <div className="h:50px w:50px m-auto">
              <img
                className="h-100 w-100 fit-contain"
                src={useCloudinary(subCat?.logoImages[0]?.url, "w_200")}
                alt=""
              />
            </div>

            <div className="product-content-wrap p-10 pt-1">
              <h2 className="text-size-14">
                <Link to={`/products/${removeSpace}`}>
                  <span>{language?.name}</span>
                </Link>
              </h2>
              <p>&nbsp;</p>
            </div>
          </div>
        </SwiperSlide>
        {subCat?.products?.map((product, i) => (
          <SwiperSlide key={i}>
            <ProductCardTwo isSmall={true} product={product} />
          </SwiperSlide>
        ))}

        <div className="slider-arrow slider-arrow-2 carausel-4-columns-arro top-40">
          <span
            className="slider-btn slider-prev slick-arrow custom_prev_f"
            style={{ display: !first && "none" }}
          >
            <i className="fi-rs-arrow-small-left"></i>
          </span>
          <span
            className="slider-btn slider-next slick-arrow custom_next_f"
            onClick={() => setFirst(true)}
          >
            <i className="fi-rs-arrow-small-right"></i>
          </span>
        </div>
      </Swiper>
    </>
  )
}

export default CategoryProductSlider

CategoryProductSlider.propTypes = {
  subCat: PropTypes.any,
  mainCat: PropTypes.any,
}

{
  /* <div className="slider-arrow slider-arrow-2 carausel-4-columns-arrow">
        <span className="slider-btn slider-prev slick-arrow custom_prev_f">
          <i className="fi-rs-arrow-small-left"></i>
        </span>
        <span className="slider-btn slider-next slick-arrow custom_next_f">
          <i className="fi-rs-arrow-small-right"></i>
        </span>
      </div> */
}
