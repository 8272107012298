import React, { useRef, useEffect } from "react"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useOutsideAlerter } from "components/hooks/useOutSideClick"
import PropTypes from "prop-types"
import Rating from "react-rating"
import { FaRegStar, FaStar } from "react-icons/fa"
import ReviewProduct from "./ReviewProduct"
import { addProductReview } from "store/actions"

const AddReviewPopup = ({ isOpen, isCloseClick, item }) => {
  const dispatch = useDispatch()
  const wrapperRef = useRef(null)

  const { company } = useSelector(state => ({
    company: state?.SiteSettings.siteSettings?.company,
  }))

  const [comment, setComment] = useState("")
  const [outSide, setOutSide] = useState(false)

  useOutsideAlerter(wrapperRef, setOutSide)

  const handleClose = () => {
    isCloseClick()
  }

  useEffect(() => {
    if (outSide === true) {
      handleClose()
      setOutSide(false)
    }
  }, [outSide])

  console.log(item)
  const product = {
    ...item?.product,
    images: [{ url: item?.product?.imageUrl }],
  }

  const handleSubmitReview = () => {
    const finalData = {
      product: item?.product?.product,
      order: item?.orderId,
      message: comment,
      rating: item?.review,
    }
    dispatch(addProductReview(finalData, handleClose))
    console.log("data :", finalData)
  }

  return (
    <>
      <div
        className={
          !isOpen
            ? "modal fade custom-modal review-modal d-none"
            : "modal fade custom-modal review-modal show d-block"
        }
      >
        <div className="modal-dialog mobile_login">
          <div className="modal-content" ref={wrapperRef}>
            <button
              type="button"
              className="btn-close"
              onClick={handleClose}
            ></button>

            <div className="modal-body myDeal-body p-0">
              <div className="comment-form pt-0">
                <h6 className="mb-15" style={{ fontWeight: "300" }}>
                  WRITE REVIEW
                </h6>
                <ReviewProduct product={product} />
                <div className="rating-star ms-3">
                  <Rating
                    emptySymbol={<FaRegStar />}
                    fullSymbol={<FaStar />}
                    className="text-warning"
                    initialRating={item?.review ?? 0}
                    onChange={review =>
                      item.setReview({
                        productId: item?.product?.product,
                        review: review,
                      })
                    }
                  />
                </div>
                <div className="row mt-3">
                  <div className="col-12">
                    <form
                      className="form-contact comment_form"
                      id="commentForm"
                    >
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <textarea
                              className="form-control w-100"
                              name="comment"
                              id="comment"
                              cols="30"
                              rows="9"
                              placeholder="Please write product reivew here."
                              onChange={e => setComment(e.target.value)}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div className="form-group d-flex-center-end">
                        <button
                          type="button"
                          onClick={handleSubmitReview}
                          className=" button button-success border-none"
                        >
                          Submit Review
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={
          !isOpen ? "modal-backdrop fade d-none" : "modal-backdrop fade show"
        }
      ></div>
    </>
  )
}

export default AddReviewPopup

AddReviewPopup.propTypes = {
  isOpen: PropTypes.bool,
  isCloseClick: PropTypes.func,
  item: PropTypes.object,
}
