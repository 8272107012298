import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import OrderContent, {
  handleOrderItemStatus,
} from "pages/checkout/OrderContent"
import TrackOrder from "./trackOrder"
import { BsArrowLeft } from "react-icons/bs"
import { useDispatch, useSelector } from "react-redux"
import { cancelOrder, getOrderDetails } from "store/actions"
import moment from "moment"
import { Link } from "react-router-dom"
import download from "assets/imgs/theme/icons/icon-download.svg"
import { exportComponentAsJPEG } from "react-component-export-image"
import ReturnOrderPopup from "components/elements/ReturnOrderPopup"
import { useWindowSize } from "react-use"
import { MainLogo } from "assets/imgs/logos"
import { map } from "lodash"
import usePrice from "components/hooks/usePrice"
import { FaSpinner } from "react-icons/fa"
import TrackMyOrderButton from "components/elements/trackOrder"
import { BsDot } from "react-icons/bs"
import { handleOrderStatus } from "./OrderItemMob"
import AddReviewPopup from "components/productUtil/AddReviewPopup"
import OrderContentMobile from "pages/checkout/OrderContentMobile"

function OrderDetails({ activeIndex, orderId, handleOnClick }) {
  const dispatch = useDispatch()
  const orderDetailCompRef = useRef()
  const { width } = useWindowSize()
  const { currencyCode } = usePrice()

  const { order, shipping, orderLoad } = useSelector(state => ({
    order: state.Cart.order,
    orderLoad: state.Cart.orderLoad,
    shipping: state.SiteSettings.siteSettings.shipping,
  }))

  const orderItems = order?.orderItems
  const defaultShippingCharge = shipping?.defaultShippingCharge

  // const [orderTrack, setOrderTrack] = useState(false)

  useEffect(() => {
    dispatch(getOrderDetails(orderId))
  }, [dispatch])

  const [isShow, setIsShow] = useState({ status: false })

  const [reviewPopup, setReviewPopup] = useState({
    status: false,
    item: {},
  })

  const address = order?.shippingInfo

  const [selectedItemIds, setSelectedItemIds] = useState([])
  const [isProcessing, setIsProcessing] = useState()

  useEffect(() => {
    const filteredItem = orderItems?.filter(
      i => i.orderItemStatus === "Processing"
    )
    const ids = filteredItem?.map(i => i._id)

    setSelectedItemIds(ids)

    if (filteredItem?.length >= 1) {
      setIsProcessing(true)
    } else {
      setIsProcessing(false)
    }
  }, [orderItems])

  const handleCancelOrder = () => {
    if (selectedItemIds?.length >= 1) {
      const input = {
        id: order?._id,
        cancelOrderItems: selectedItemIds,
      }
      dispatch(cancelOrder(input))
    }
  }

  const handleReturnOrder = () => {
    setIsShow({ ...isShow, status: true })
  }

  const today = new Date()

  const todayDate = moment(today).format("DD MM YYYY")

  const orderedDate = moment(order?.createdAt).format("DD MM YYYY")

  return (
    <>
      <AddReviewPopup
        isOpen={reviewPopup?.status}
        isCloseClick={() =>
          setReviewPopup({
            status: false,
            items: {},
          })
        }
        item={reviewPopup?.item}
      />

      <ReturnOrderPopup isShow={isShow?.status} setIsShow={setIsShow} />

      <div
        className={
          activeIndex === 3 ? "tab-pane fade active show" : "tab-pane fade "
        }
      >
        <div className="card">
          <div className="card-header d-flex align-items-center justify-content-between ">
            <h4 className="mb-0 orderHead">
              <BsArrowLeft
                className="mx-1 cursor-pointer"
                onClick={() => handleOnClick(2)}
              />
              Order Details
            </h4>
            {/* <span className="d-flex align-items-center mb-1">
              <BsDot size={"1.2rem"} />
              <h5 className="text-brand" style={{ fontWeight: "600" }}>
                {order?.orderStatus}
              </h5>
            </span> */}

            <div
              className={`d-flex align-items-center justify-content-end ${handleOrderStatus(
                order?.orderStatus
              )}`}
            >
              <BsDot size={"1.2rem"} />
              <p className="font-smaller">{order?.orderStatus}</p>
            </div>
          </div>

          {width >= 480 ? (
            <div className="card-body">
              {/* <div className="px-4">
                <h6
                  className="text-secondary mb-30 "
                  onClick={() => setOrderTrack(!orderTrack)}
                >
                  Track your order
                  <span className="mx-2"></span>
                </h6>
                <div>
                  {order?.orderStatus === "Returned" ||
                  order?.orderStatus === "Cancelled" ? (
                    <></>
                  ) : (
                    <TrackOrder />
                  )}
                </div>
              </div> */}

              <div className="w-100 d-flex align-item-center justify-content-start mb-20 border-b-4 pb-4">
                {width >= 480 && (
                  <a className={""}>
                    <div className="w-100 d-flex align-item-center justify-content-end">
                      {order?.orderStatus === "Processing" &&
                        isProcessing === true && (
                          <Link
                            to={"#!"}
                            id="invoice_download_btn"
                            onClick={() => handleCancelOrder()}
                            style={{ fontWeight: "100" }}
                            className="text-black"
                          >
                            Cancel
                            {orderLoad && (
                              <FaSpinner className="mx-2 spinner" />
                            )}
                          </Link>
                        )}
                      {order?.orderStatus === "Delivered" && (
                        <Link
                          to={"#!"}
                          id="invoice_download_btn"
                          onClick={() => handleReturnOrder()}
                          className="btn btn-lg btn-custom bg-light text-black hover-up border-1-gray"
                        >
                          Return
                          {orderLoad && <FaSpinner className="mx-2 spinner" />}
                        </Link>
                      )}
                    </div>
                  </a>
                )}
              </div>

              <div ref={orderDetailCompRef} className="p-1.5">
                <div className="h:40px w:100px mb-3 ms-auto">
                  <img
                    src={MainLogo}
                    alt="logo"
                    className="h-100 w-100 fit-contain"
                  />
                </div>
                <div className="d-flex align-items-start justify-content-between mb-30 sm:flex-col">
                  {" "}
                  <div className="text sm:mb-10">
                    <h6 className="mb-5">Shipping Address</h6>
                    <strong className="text-brand text-size-20">
                      {address?.name}
                    </strong>{" "}
                    <p>{address?.contactNumber}</p>
                    <p>{address?.email}</p>
                    <span>{address?.addressLine1}</span>
                    <p>{address?.street}</p>
                    <p>{address?.landmark}</p>
                    <p>{address?.area}</p>
                    <p>{address?.district}</p>
                    {address?.address}
                  </div>
                  <div className="text-end">
                    <h6>
                      Order ID:{" "}
                      <span className="text-brand">{order?.orderId}</span>
                    </h6>
                    <p>
                      {" "}
                      <span className="">
                        {" "}
                        {moment(order?.createdAt).format("DD MMM, YYYY LT")}
                      </span>
                    </p>
                  </div>
                </div>

                {order?.orderId && (
                  <OrderContent
                    isNavbar={false}
                    isFooter={false}
                    isAccount={true}
                    setReviewPopup={setReviewPopup}
                  />
                )}
              </div>

              {width > 768 && (
                <div
                  className={
                    "invoice-btn-section clearfix d-print-none d-flex align-items-center sm:align-items-end justify-content-end sm:flex-col"
                  }
                >
                  <Link
                    to={"#!"}
                    id="invoice_download_btn"
                    onClick={() => exportComponentAsJPEG(orderDetailCompRef)}
                    className="btn btn-lg btn-custom bg-info  btn-download hover-up"
                  >
                    {" "}
                    <img src={download} alt="" /> Download
                  </Link>
                </div>
              )}
            </div>
          ) : (
            <OrderContentMobile setReviewPopup={setReviewPopup} />
          )}
        </div>
      </div>
    </>
  )
}

export default OrderDetails
OrderDetails.propTypes = {
  activeIndex: PropTypes.any,
  orderId: PropTypes.any,
  handleOnClick: PropTypes.func,
}
