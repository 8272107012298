import React from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import useLangSwitch from "components/hooks/useLanguageSwicth"
import usePrice from "components/hooks/usePrice"
import useCloudinary from "components/hooks/useCloudinaryImage"
import { useSelector } from "react-redux"
import { TbTruckDelivery } from "react-icons/tb"

function ListVertical({ product }) {
  const { currencyCode, oldPrice, price, stock } = usePrice(product)

  const { freeShippingThreshold } = useSelector(state => ({
    freeShippingThreshold:
      state.SiteSettings.siteSettings.shipping?.freeShippingThreshold,
  }))

  const language = useLangSwitch(product?.primaryLang, product?.secondaryLang)

  const removeSpace = product?.primaryLang?.name?.replace(/ /g, "-")

  const diff = Math.abs(oldPrice - price)

  const discountPercentage =
    (oldPrice ?? 0) > price ? Math.floor((diff / oldPrice) * 100) : ""

  return (
    <>
      <div className="product-list-small  animated animated">
        <article className="row align-items-start hover-up">
          <figure className="col-md-4 mb-0">
            <Link to={`/products/${removeSpace}`}>
              <img
                src={useCloudinary(product.images[0]?.url, "c_thumb,w_350")}
                alt=""
              />
            </Link>
          </figure>
          <div className="col-md-8 mb-0 prd_vertical">
            <h2>
              <Link
                className="font-normal"
                style={{ fontWeight: "normal" }}
                to={`/products/${removeSpace}`}
              >
                {language?.name?.slice(0, 50)} &nbsp;
                {language?.name?.length > 50 && "..."}
              </Link>
            </h2>
            <div className="product-price">
              <div className="product-card-bottom mt-0">
                <div className="product-price">
                  {oldPrice && (
                    <>
                      <span
                        className="old-price font-normal m-0"
                        style={{ fontWeight: "400" }}
                      >
                        {oldPrice}
                      </span>
                      &nbsp;
                    </>
                  )}
                  <span
                    className="font-head text-black"
                    style={{ margin: "0 5px 0 5px", fontWeight: "500" }}
                  >
                    {currencyCode}
                    {price}&nbsp;
                  </span>
                  {(product?.discountPercentage > 0 ||
                    discountPercentage > 0) && (
                    <span className="price-per">
                      {product?.discountPercentage ?? discountPercentage}% off
                    </span>
                  )}
                </div>
              </div>
              <div className="d-flex align-items-start mt-1">
                {price >= freeShippingThreshold ? (
                  <p
                    style={{ fontSize: "11px", lineHeight: "11px" }}
                    className="text-green me-1  d-flex align-items-center"
                  >
                    <TbTruckDelivery className="me-1" /> Free Delivery
                  </p>
                ) : (
                  <p>&nbsp;</p>
                )}
                {stock >= 1 && stock <= 9 ? (
                  <p
                    style={{
                      fontSize: "11px",
                      lineHeight: "11px",
                      fontWeight: "500",
                    }}
                    className="text-green "
                  >
                    Only {stock} left
                  </p>
                ) : (
                  <p>&nbsp;</p>
                )}
              </div>
            </div>
          </div>
        </article>
      </div>
    </>
  )
}

export default ListVertical

ListVertical.propTypes = {
  product: PropTypes.any,
}
