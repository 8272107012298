import React from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import useLangSwitch from "components/hooks/useLanguageSwicth"
import usePrice from "components/hooks/usePrice"
import { useDispatch } from "react-redux"
import useCartActions from "components/hooks/useCartAction"
import { addOrRemoveFav, getLocalCart } from "store/actions"
import useCloudinary from "components/hooks/useCloudinaryImage"

function CartItemMob({ item, isWish }) {
  const dispatch = useDispatch()
  const product = item?.product

  const language = useLangSwitch(product?.primaryLang, product?.secondaryLang)

  const { currencyCode, price, oldPrice, currentVarient } = usePrice(product)

  const token = localStorage.getItem("idealeshope_token")

  const deleteFromCart = item => {
    const options = {
      product: item?.product?._id,
      varient: item?.varient,
      operation: "substract",
      quantity: item?.quantity ?? 1,
    }
    useCartActions(options, dispatch, item?.product)
    if (!token) {
      dispatch(getLocalCart())
    }
  }

  const increaseQuantity = item => {
    const options = {
      product: item?.product?._id,
      varient: item?.varient,
      operation: "add",
    }
    useCartActions(options, dispatch, item?.product)
    if (!token) {
      dispatch(getLocalCart())
    }
  }

  const decreaseQuantity = item => {
    if (item?.quantity <= 1) {
      return
    } else {
      const options = {
        product: item?.product?._id,
        varient: item?.varient,
        operation: "substract",
      }
      useCartActions(options, dispatch, item?.product)
      if (!token) {
        dispatch(getLocalCart())
      }
    }
  }

  const currentVarient2 = item?.product?.varients?.filter(
    i => i._id === item?.varient
  )[0]

  const handleStockText = () => {
    if (item?.isOutOfStock === true) {
      if (currentVarient2?.stock <= 0) {
        return "Out of Stock"
      } else if (item?.quantity > currentVarient2?.stock) {
        return "Only " + currentVarient2?.stock + " left"
      }
    } else return ""
  }

  const deleteFromWishlist = productId => {
    dispatch(addOrRemoveFav(productId))
  }

  const removeSpace = language?.name?.replace(/ /g, "-")

  return (
    <>
      {item?.product !== null && (
        <div
          className="d-flex mb-10 border-b-4 justify-content-between"
          style={{ paddingBottom: "10px" }}
        >
          <div className="d-flex align-items-start flex-1 ">
            <div className="text-center">
              <div className="h:100px w:100px mb-2">
                <img
                  src={useCloudinary(product.images[0]?.url, "h_400,w_400")}
                  className="rounded-sm"
                  onClick={() => history.push(`/products/${removeSpace}`)}
                />
                {handleStockText() && (
                  <p className="text-danger pb-1">! {handleStockText()}</p>
                )}
              </div>
            </div>

            <div className="mx-2 flex-1">
              <h6 className="product-name text-black">
                <Link to={`/products/${removeSpace}`} className="text-black">
                  {language?.name?.length > 35
                    ? language?.name?.slice(0, 35) + "..."
                    : language?.name}{" "}
                  {item?.varientValue
                    ? `(${item?.varientValue} ${item?.varientUnit})`
                    : currentVarient}
                </Link>
              </h6>

              {isWish && (
                <div className="text-end  mx-2">
                  <a onClick={e => deleteFromWishlist(product?._id)}>
                    <i className="fi-rs-trash"></i>
                  </a>
                </div>
              )}

              {!isWish && (
                <>
                  <div className="d-flex justify-content-start">
                    {oldPrice && <del className="me-2">{oldPrice}</del>}
                    <h5 className="text-body text-black font-mainHead mb-1">
                      {currencyCode}{" "}
                      {item?.offerPrice
                        ? item?.offerPrice
                        : item?.price ?? price}
                    </h5>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="detail-extralink mob_detail_extralink">
                      <div className="detail-qty border radius m-0 ">
                        <a
                          onClick={() => decreaseQuantity(item)}
                          className="qty-down"
                        >
                          <i className="fi-rs-angle-small-down"></i>
                        </a>

                        <span className="qty-val">{item?.quantity}</span>
                        <a
                          onClick={() => increaseQuantity(item)}
                          className="qty-up"
                        >
                          <i className="fi-rs-angle-small-up"></i>
                        </a>
                      </div>
                    </div>
                    <div className="d-flex flex-col justify-content-center align-items-center mx-2">
                      <a onClick={() => deleteFromCart(item)}>
                        <i className="fi-rs-trash font-md"></i>
                      </a>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default CartItemMob

CartItemMob.propTypes = {
  item: PropTypes.any,
  isWish: PropTypes.bool,
}
