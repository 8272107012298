import React from "react"
import { Link, useHistory } from "react-router-dom"
import PropTypes from "prop-types"
import usePrice from "components/hooks/usePrice"
import useCloudinary from "components/hooks/useCloudinaryImage"

function ReviewProduct({ product, setsHowList }) {
    const history = useHistory()
    const { currencyCode, oldPrice, price, currentVarient } = usePrice(product)

    const removeSpace = product?.primaryLang?.name?.replace(/ /g, "-")

    console.log(price);
    return (
        <>
            <div
                className="review-product mb-sm-5 mb-md-0 mt-3 wow animate__animated animate__fadeInUp"
                data-wow-delay="0"
                onClick={() => history.push(`/products/${removeSpace}`)}
            >
                <div className="product-list-small review-product-list  animated animated">
                    <article
                        className="d-flex align-items-center hover-up"
                        onClick={() => setsHowList(false)}
                    >
                        <figure className="mb-0 mx-3">
                            <Link to={`/products/${removeSpace}`} className="d-flex">
                                <img
                                    src={useCloudinary(
                                        product.images[0]?.url,
                                        "c_thumb,h_200,w_200"
                                    )}
                                    className="h:80px w:80px"
                                    alt="babyDay-product"
                                    title="babyday-product"
                                    loading="eager"
                                />
                            </Link>
                        </figure>
                        <div className="col-md-8 mb-0">
                            <p style={{ fontSize: "14px" }}>
                                <Link className="text-black" to={`/products/${removeSpace}`}>
                                    {product?.primaryLang?.name}
                                    {
                                        product?.varientUnit && product?.varientValue ? <>
                                            &nbsp;    ({product?.varientUnit} {product?.varientValue} )
                                        </> : <>
                                            {currentVarient && <span>({currentVarient})</span>}</>
                                    }

                                </Link>
                            </p>
                            <div className="product-price">
                                <span className="text-black">
                                    {currencyCode} {price === undefined ? product?.price : price}{" "}
                                </span>
                                <span className="old-price ">{oldPrice}</span>
                            </div>
                        </div>
                    </article>
                </div>
            </div>
        </>
    )
}

export default ReviewProduct
ReviewProduct.propTypes = {
    product: PropTypes.any,
    setsHowList: PropTypes.func,
}
