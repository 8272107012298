// Dashboard
import Pages500 from "pages/404/pages-500"
import Pages404 from "pages/404/pages-404"
import Home from "pages/Home/home1"
import ProductSingleView from "pages/product"
import Cart from "pages/shop/shop-cart"
import ProductsList from "pages/shop/shop-list-left"
import Account from "pages/user/page-account"
import Contact from "pages/user/page-contact"
import WishlistModal from "pages/shop/shop-wishlist"
import About from "pages/static/page-about"
import StaticPage from "pages/static/StaticPage"
import Checkout from "pages/checkout"
import OrderSuccess from "pages/checkout/OrderSuccess"
import AllCategoryList from "pages/category"
import OrderDetails from "pages/user/order/orderDetails"
import CheckoutLogin from "pages/checkout/checkoutLogin"
import SearchPage from "components/ecommerce/search/index"

const publicRoutes = [
  //defualt routes
  { path: "/404", component: Pages404 },
  { path: "/500", component: Pages500 },
  { path: "/", component: Home },
  //main routes
  // { path: "/custom/:type", component: CustomGrid },
  // { path: "/custom/:type/:name", component: CustomGrid },

  { path: "/products/:name", component: ProductSingleView },
  { path: "/categroies", component: AllCategoryList },
  { path: "/product", component: ProductsList },
  // { path: "/collections", component: AllColllection },
  // { path: "/collections/:name", component: SingleCollection }, //pending

  //static routes
  { path: "/contact", component: Contact },
  { path: "/about", component: About },

  { path: "/privacy-policy", component: StaticPage },
  { path: "/return-policy", component: StaticPage },
  { path: "/terms", component: StaticPage },

  { path: "/cart", component: Cart },
  { path: "/wishlist", component: WishlistModal },
  { path: "/search", component: SearchPage },
  // { path: "/quick-purchase", component: shopQuickPurchase },

  { path: "/checkout/login", component: CheckoutLogin },
]

const AuthProtectedRoute = [
  { path: "/user-account", component: Account },
  { path: "/user/order/:id", component: OrderDetails },
  { path: "/checkout", component: Checkout },
  { path: "/order-success", component: OrderSuccess },
]

export { publicRoutes, AuthProtectedRoute }
