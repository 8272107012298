import Layout from "components/layout/Layout"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { clearOrderState, createEarningFromOrder } from "store/actions"

import OrderContent from "./OrderContent"

function OrderSuccess() {
  const dispatch = useDispatch()
  const ref_id = localStorage?.getItem("ref_id")

  const { order } = useSelector(state => ({
    order: state.Cart.order,
  }))

  useEffect(() => {
    dispatch(clearOrderState())
  }, [dispatch])

  useEffect(() => {
    if (ref_id) {
      const credentials = {
        orderId: order?._id,
        ref_id: ref_id,
      }
      dispatch(createEarningFromOrder(credentials))
    }
  }, [ref_id])

  return (
    <Layout parent="Home" sub="Checkout" subChild="Success">
      <div className="invoice invoice-content invoice-5">
        <div className="p-2 m-auto invoice-item" style={{ maxWidth: "1200px" }}>
          <OrderContent isNavbar={true} isShopMore={true} isSuccess={true} />
        </div>
      </div>
    </Layout>
  )
}

export default OrderSuccess
