import React, { useEffect } from "react"
import ProductDetails from "../../components/ecommerce/productCards/ProductDetails"
import Layout from "../../components/layout/Layout"
import PropTypes from "prop-types"
import { useLocation, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { getProductDetail, getRelatedProducts } from "store/actions"
import useLangSwitch from "components/hooks/useLanguageSwicth"
import ProductLoader from "components/Common/loaders/ProductDetailsLoader"
import MetaData from "util/MetaData"
import useMetaHandler from "components/hooks/useMetaHandler"
import useCloudinary from "components/hooks/useCloudinaryImage"

const ProductSingle = () => {
  const params = useParams()
  const location = useLocation()
  const dispatch = useDispatch()

  const { productDetail, loading } = useSelector(state => ({
    productDetail: state.Products.productDetail.product,
    loading: state.Products.loading,
  }))

  const removeArrow = params?.name?.replace(/-/g, " ")

  useEffect(() => {
    const isRef = location?.search?.split("=")
    if (isRef[0] === "?ref_id") {
      localStorage?.setItem("ref_id", isRef[1])
    }
  }, [location?.search])

  useEffect(() => {
    dispatch(getProductDetail(removeArrow + location?.search))
    dispatch(getRelatedProducts(removeArrow))
  }, [dispatch, removeArrow])

  const language = useLangSwitch(
    productDetail?.primaryLang,
    productDetail?.secondaryLang
  )

  const {
    metaDescription,
    metaKeyword,
    metaTitle,
    canonicalLink,
    metaProduct,
    image,
  } = useMetaHandler(language?.name, language?.description, productDetail)

  return (
    <>
      <MetaData
        content={language?.name}
        title={metaTitle}
        description={metaDescription ? metaDescription : language?.name}
        keyword={metaKeyword}
        canonicalLink={canonicalLink}
        ogImage={image}
        product={metaProduct}
      />
      <Layout
        parent="Home"
        sub={productDetail?.categories[0]?.primaryLang?.name}
        subChild={language?.name}
      >
        <div className="container sm:p-0">
          {loading ? (
            <ProductLoader />
          ) : (
            productDetail?._id && <ProductDetails product={productDetail} />
          )}
        </div>
      </Layout>
    </>
  )
}
export default ProductSingle

ProductSingle.propTypes = {
  product: PropTypes.any,
}
