import React from "react"
import { Link } from "react-router-dom"
import { useBanner } from "./useBannerData"
import PropTypes from "prop-types"
import FeatchDeals2 from "components/ecommerce/fetchDeals2"

function CollectionG4({ groupDetail }) {
  const banner = useBanner(
    groupDetail?.collections,
    groupDetail?.categories,
    groupDetail?.type
  )

  return (
    <>
      <section className="section-padding pb-5">
        <div className="container">
          <div
            className="section-title mb-4 wow animate__animated animate__fadeIn"
            data-wow-delay="0"
          >
            <h3 className="sm:font-size-16 section_head_h3">
              {groupDetail?.primaryLang?.title}
            </h3>

            {groupDetail?.type === "collections" ? (
              <Link to={`/collections`}>
                <span className="show-all">
                  Explore
                  <i className="fi-rs-angle-right"></i>
                </span>
              </Link>
            ) : (
              <Link to={`/categroies`}>
                <span className="show-all">
                  Explore
                  <i className="fi-rs-angle-right"></i>
                </span>
              </Link>
            )}
          </div>
          <FeatchDeals2 listData={banner} />
        </div>
      </section>
    </>
  )
}

export default CollectionG4

CollectionG4.propTypes = {
  groupDetail: PropTypes.object,
}
