import React from "react"
import PropTypes from "prop-types"
import UserAddressList from "pages/checkout/userAddressList"
import TableLoader from "components/Common/loaders/TableLoader"
import { useSelector } from "react-redux"

function MyAccount({ activeIndex }) {
  const { loading } = useSelector(state => ({
    // allAddress: state.Cart.uesrAddress.shippingAddresses,
    loading: state.Cart.addressLoad,
  }))

  // const isDefaultAddress = allAddress?.filter(i => i?.isDefault === true)[0]
  // const isRecentlyAddress = allAddress?.filter(
  //   i => i?.isRecentlyAdded === true
  // )[0]

  // const shippingInfo =
  //   isDefaultAddress?.shippingInfo ?? isRecentlyAddress?.shippingInfo

  return (
    <div
      className={
        activeIndex === 4 ? "tab-pane fade active show" : "tab-pane fade "
      }
    >
      {loading ? (
        <TableLoader />
      ) : (
        <div className="row">
          <UserAddressList isAccount={true} />

          {/* <div className="col-lg-6">
            <div className="card">
              <div className="card-body">
                <h5 className="mb-1 sm:font-size-18 ">
                  {isDefaultAddress
                    ? "Default Shipping Address"
                    : "Recently Used Address"}{" "}
                </h5>
                <p className="sm:font-size-14 fw-600">{shippingInfo?.name}</p>
                <p className="m-0 p-0 sm:font-size-14 fw-600">
                  {shippingInfo?.contactNumber}
                </p>
                <p className="p-0 m-0 sm:font-size-14">{shippingInfo?.email}</p>
                <address>{shippingInfo?.address}</address>
              </div>
            </div>
          </div> */}
        </div>
      )}
    </div>
  )
}

export default MyAccount

MyAccount.propTypes = {
  activeIndex: PropTypes.any,
}
