import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link, useHistory } from "react-router-dom"
import TableLoader from "components/Common/loaders/TableLoader"
import { useDispatch, useSelector } from "react-redux"
import { getUserOrders, reOrder } from "store/actions"
import { map } from "lodash"
import moment from "moment"
import usePrice from "components/hooks/usePrice"
import { useWindowSize } from "react-use"
import CustomizedLoader from "components/Common/loaders/CustomizedLoader"
import InfiniteScroll from "react-infinite-scroll-component"
import OrderItemMob from "./OrderItemMob"
import ProductListLoader from "components/Common/loaders/ProductListLoader"
import TrackMyOrderButton from "components/elements/trackOrder"

function UserOrders({ activeIndex, handleOnClick }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const { width } = useWindowSize()

  const { orders, totalOrders, loading } = useSelector(state => ({
    orders: state.Cart.allOrder.orders,
    loading: state.Cart.orderLoad,
    totalOrders: state.Cart.allOrder.totalOrders,
  }))

  const { currencyCode } = usePrice()

  const handleOrderDetailsView = id => {
    handleOnClick(3, id)
  }

  const handleOrderStatus = status => {
    if (status === "Processing") return "text-black"
    if (status === "Shipped") return "text-green"
    if (status === "Delivered") return "text-info"
    if (status === "Returned") return "text-danger"
    if (status === "Cancelled") return "text-danger"
  }

  const handleOrderAgain = id => {
    dispatch(reOrder(id, history))
  }

  //pagination
  const limit = 10
  const allpages = totalOrders / limit
  let [pages, setPages] = useState(1)

  const HandlefetchNextPage = () => {
    if (pages < allpages) {
      setPages(pages + 1)
    }
  }

  useEffect(() => {
    dispatch(getUserOrders(pages, limit))
  }, [dispatch, pages, limit])

  const OrderItemWeb = (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr className="bg-pista ">
            <th>Order ID</th>
            <th>Date</th>
            <th>Status</th>
            <th>Total</th>
            <th>Payment</th>
            <th>
              <h6 className="my-2">Actions</h6>
            </th>
          </tr>
          <tr></tr>
        </thead>
        <tbody>
          {map(orders, (order, key) => (
            <tr className="border-none border-b-4" key={key}>
              <td>
                <Link
                  to="#!"
                  className="text-black "
                  style={{ textDecoration: "underline" }}
                  onClick={() => handleOrderDetailsView(order?._id)}
                >
                  {order?.orderId}
                </Link>
              </td>
              <td className="white-space-nowrap">
                {moment(order?.createdAt).format("MMM DD, YYYY")}
              </td>
              <td className={handleOrderStatus(order?.orderStatus)}>
                {order?.orderStatus}
              </td>
              <td>
                <span className="text-warning">
                  {currencyCode} {order?.totalPrice}
                </span>{" "}
              </td>
              <td>
                <span
                  className={
                    order?.paymentType === "Cash on Delivery"
                      ? "text-gray"
                      : "text-green"
                  }
                >
                  {order?.paymentType}
                </span>
              </td>
              <td className="d-flex align-items-center justify-content-between">
                <Link
                  to="#!"
                  onClick={() => handleOrderDetailsView(order?._id)}
                  className="btn-small d-block my-3"
                >
                  View
                </Link>

                {order?.orderStatus === "Shipped" && order?.awbNumber && (
                  <TrackMyOrderButton
                    awbNumber={order?.awbNumber}
                    className="btn-sm btn btn-custom bg-info btn-download hover-up"
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )

  return (
    <div
      className={
        activeIndex === 1 ? "tab-pane fade active show" : "tab-pane fade "
      }
    >
      <div className="card">
        <div className="card-header d-flex justify-content-between">
          <h5 className="mb-0 mt-2">Your Orders</h5>
        </div>

        <InfiniteScroll
          dataLength={orders?.length ?? 0}
          next={HandlefetchNextPage}
          hasMore={true}
          loader={loading && <ProductListLoader key={0} />}
        >
          <div className="card-body sm:px-0">
            {loading ? (
              <>
                {width >= 480 ? (
                  <TableLoader />
                ) : (
                  <CustomizedLoader
                    mobileCount={4}
                    containerClass={"flex row"}
                    style={{ height: "150px" }}
                  />
                )}
              </>
            ) : (
              <>
                {width >= 480 ? (
                  OrderItemWeb
                ) : (
                  <OrderItemMob
                    singleView={handleOrderDetailsView}
                    orderAgain={handleOrderAgain}
                  />
                )}
              </>
            )}
          </div>
        </InfiniteScroll>

        {/* <div className="pagination-area mt-15 mb-sm-5 mb-lg-0">
          <nav aria-label="Page navigation example">
            <Pagination
              getPaginationGroup={getPaginationGroup}
              currentPage={currentPage}
              pages={pages}
              next={next}
              prev={prev}
              handleActive={handleActive}
            />
          </nav>
        </div> */}
      </div>
    </div>
  )
}

export default UserOrders

UserOrders.propTypes = {
  activeIndex: PropTypes.any,
  handleOnClick: PropTypes.func,
}
