import React, { Fragment } from "react"
import PropTypes from "prop-types"
import CartItem from "./cartItemComp"

function CartItemWeb({ cartItems }) {
  return (
    <table
      id="custom_cart_table"
      className={cartItems?.length > 0 ? "table table-wishlist" : "d-none"}
    >
      <thead>
        <tr className="main-heading border-none">
          <th
            style={{ fontWeight: "400" }}
            className="custome-checkbox start pl-30"
            colSpan="2"
          >
            Product
          </th>
          <th style={{ fontWeight: "400" }} scope="col">
            Unit Price
          </th>
          <th style={{ fontWeight: "400" }} scope="col">
            Quantity
          </th>
          <th style={{ fontWeight: "400" }} scope="col">
            Subtotal
          </th>
          <th style={{ fontWeight: "400" }} scope="col" className="end">
            &nbsp;
          </th>
        </tr>
      </thead>
      <tbody>
        {cartItems?.map((item, i) => (
          <Fragment key={i}>
            <CartItem item={item} key={i} />
          </Fragment>
        ))}
      </tbody>
    </table>
  )
}

export default CartItemWeb

CartItemWeb.propTypes = {
  cartItems: PropTypes.any,
}
