import Layout from "components/layout/Layout"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory, useLocation } from "react-router-dom"
import { useWindowSize } from "react-use"
import { getUserAddress, logoutUser } from "store/actions"
import MyAccount from "./myAccount/MyAccount"
import UserInfoChange from "./myAccount/UserInfoChange"
import OrderDetails from "./order/orderDetails"
import UserOrders from "./order/Orders"
import MyReview from "./myReview/MyReview"

function Account() {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { width } = useWindowSize()

  const [activeIndex, setActiveIndex] = useState(1)
  const [orderId, setOrderId] = useState()

  const { user } = useSelector(state => ({
    user: state.Login.user,
  }))

  const handleOnClick = (index, orderId) => {
    setActiveIndex(index)
    setOrderId(orderId)
  }

  const index = location?.state?.index
  useEffect(() => {
    if (index) {
      setActiveIndex(index)
    }
  }, [index])

  const handleLogout = () => {
    dispatch(logoutUser(history))
  }

  useEffect(() => {
    dispatch(getUserAddress())
  }, [])

  useEffect(() => {
    if (activeIndex === 3) {
      window?.scroll(0, 0)
    }
  }, [])

  const tablist = [
    {
      title: "Orders",
      icon: <i className="fi-rs-shopping-bag mr-10 sm:d-none"></i>,
      tabId: 1,
    },
    {
      title: "My Reviews",
      icon: <i className="fi-rs-badge mr-10 sm:d-none"></i>,
      tabId: 2,
    },
    {
      title: "My Address",
      icon: <i className="fi-rs-marker mr-10 sm:d-none"></i>,
      tabId: 4,
    },
    {
      title: "Account Details",
      icon: <i className="fi-rs-user mr-10 sm:d-none"></i>,
      tabId: 5,
    },
  ]

  return (
    <>
      <Layout parent="Home" sub="Pages" subChild="Account">
        <div className="page-content pt-60 pb-80">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 m-auto">
                {width <= 480 && activeIndex === 1 && (
                  <h6 className="mb-3 ">
                    Hello!{" "}
                    <span className="text-brand">
                      {user?.name ? user?.name : ""}
                    </span>
                  </h6>
                )}
                <div className="row">
                  {activeIndex === 3 && width <= 480 ? (
                    <></>
                  ) : (
                    <div className="col-md-3">
                      <div className="dashboard-menu">
                        <ul
                          className="nav flex-column sm:flex-row sm:justify-content-between"
                          role="tablist"
                        >
                          {tablist?.map((tab, key) => (
                            <li key={key} className="nav-item sm:w-49">
                              <a
                                className={
                                  activeIndex === tab?.tabId
                                    ? "nav-link active"
                                    : "nav-link"
                                }
                                onClick={() => handleOnClick(tab?.tabId)}
                              >
                                {tab?.icon}
                                {tab?.title}
                              </a>
                            </li>
                          ))}
                          <li className="nav-item sm:w-49 h-fit">
                            <Link
                              to={"#!"}
                              className={"nav-link"}
                              onClick={handleLogout}
                            >
                              <i className="fi-rs-sign-out mr-10 sm:d-none "></i>
                              Logout
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                  <div className="col-md-9">
                    <div className="tab-content account dashboard-content pl-50 sm:p-0">
                      {/* <DashBoard
                        activeIndex={activeIndex}
                        handleOnClick={handleOnClick}
                      /> */}
                      <UserOrders
                        activeIndex={activeIndex}
                        handleOnClick={handleOnClick}
                      />
                      <MyAccount activeIndex={activeIndex} />
                      <UserInfoChange activeIndex={activeIndex} />
                      {orderId && (
                        <OrderDetails
                          activeIndex={activeIndex}
                          orderId={orderId}
                          handleOnClick={handleOnClick}
                        />
                      )}
                      <MyReview activeIndex={activeIndex} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Account
