import React from "react"
import Layout from "components/layout/Layout"
import { Link, useLocation } from "react-router-dom"
import { useSelector } from "react-redux"
import { year } from "constants/layout"
import { Markup } from "interweave"

function StaticPage() {
  const location = useLocation()

  const { company, legal } = useSelector(state => ({
    company: state?.SiteSettings.siteSettings?.company,
    company: state?.SiteSettings.siteSettings?.company,
    legal: state?.SiteSettings.siteSettings?.legal,
  }))

  const handleStaticPage = () => {
    if (location?.pathname === "/privacy-policy")
      return {
        data: legal?.privacyPolicy,
        title: "Privacy Policy",
      }
    if (location?.pathname === "/return-policy")
      return {
        data: legal?.returnPolicy,
        title: "Return Policy",
      }
    if (location?.pathname === "/terms")
      return {
        data: legal?.terms,
        title: "Terms of Service",
      }
  }
  return (
    <>
      <Layout parent="Home" sub="Pages" subChild="Terms">
        <div className="page-content pt-50">
          <div className="container">
            <div className="row">
              <div className="col-xl-10 col-lg-12 m-auto">
                <div className="row">
                  <div className="col-lg-9 mb-50">
                    <div className="single-page pr-30 mb-lg-0 mb-sm-5">
                      <div className="single-header style-2">
                        <h2>{handleStaticPage()?.title}</h2>
                        <div className="entry-meta meta-1 meta-3 font-xs mt-15 mb-15">
                          <span className="post-by">
                            By <a href="#">{company?.name}</a>
                          </span>
                          <span className="post-on has-dot">{year}</span>
                        </div>
                      </div>
                      <div className="single-content mb-50">
                        <Markup content={handleStaticPage()?.data} />
                      </div>
                    </div>

                    <Link to="/product">
                      <a className="btn btn-default submit-auto-width font-xs hover-up mt-30">
                        <i className="fi-rs-home mr-5"></i> Back To Shopping
                      </a>
                    </Link>
                  </div>
                  <div className="col-lg-3 primary-sidebar sticky-sidebar"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default StaticPage
