import React from "react"
import PropTypes from "prop-types"
import { Link, useHistory } from "react-router-dom"
import { map } from "lodash"
import useCloudinary from "components/hooks/useCloudinaryImage"

function FeatchDeals2({ listData }) {
  const history = useHistory()

  const handleRouteLink = name => {
    if (name?.title) {
      const CollectionLink = name?.title?.replace(/ /g, "-")
      return history.push(`/collections/${CollectionLink}`)
    } else if (name?.name) {
      const CategoryLink = name?.name?.replace(/ /g, "-")
      return history.push({
        pathname: "/product",
        query: {
          cat: CategoryLink,
          isProduct: false,
        },
      })
    } else return
  }

  return (
    <>
      <div className="row">
        {map(listData, (item, key) => (
          <div
            className={"newCollBanner-col col-lg-4 col-md-6 col-6 col-xl-3"}
            key={key}
          >
            <div
              className="product-cart-wrap style-2 wow animate__animated animate__fadeInUp newCollBanner-style"
              data-wow-delay="0"
            >
              <div className="product-img-action-wrap">
                <div className="product-img newCollBanner">
                  <span
                    onClick={() => handleRouteLink(item?.primaryLang)}
                    className="h-100 w-100 cursor-pointer"
                  >
                    <img
                      src={useCloudinary(item?.bannerImages[0]?.url, "w_1200")}
                      alt=""
                      className="fit-cover  border-radious-0"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}
export default FeatchDeals2

FeatchDeals2.propTypes = {
  listData: PropTypes.any,
}
