import React from "react"
import PropTypes from "prop-types"
import { Link, useHistory } from "react-router-dom"

import { map } from "lodash"
import useCloudinary from "components/hooks/useCloudinaryImage"

function CollectionList({ listData, col }) {
  const history = useHistory()

  const handleRouteLink = name => {
    if (name?.title) {
      const CollectionLink = name?.title?.replace(/ /g, "-")
      return history.push(`/collections/${CollectionLink}`)
    } else if (name?.name) {
      const CategoryLink = name?.name?.replace(/ /g, "-")
      return history.push({
        pathname: "/product",
        query: {
          cat: CategoryLink,
          isProduct: false,
        },
      })
    } else return
  }
  return (
    <>
      {map(listData, (item, key) => (
        <div
          className={`col-lg-${col ? col : 4} col-md-6 mb-4 sm:mb-0`}
          key={key}
        >
          <div
            className="banner-img mobileContainer wow animate__animated animate__fadeInUp max-h:300px w-100"
            data-wow-delay="0"
          >
            <span
              onClick={() => handleRouteLink(item?.primaryLang)}
              className="cursor-pointer"
            >
              <img
                src={useCloudinary(item?.bannerImages[0]?.url ?? "", "w_1000")}
                alt=""
                className="fit-cover h-100 w-100 object-start mobileBanner"
              />
            </span>
            <div className="banner-text">
              {/* <h4 className="mb-0">&nbsp;</h4> */}
              {/* <Link to="/products">
             <span className="btn btn-xs text-white">
               Shop Now <i className="fi-rs-arrow-small-right"></i>
             </span>
           </Link> */}
            </div>
          </div>
        </div>
      ))}
    </>
  )
}

export default CollectionList

CollectionList.propTypes = {
  listData: PropTypes.any,
  col: PropTypes.number,
}
