import React from 'react'
import PropTypes from "prop-types"
import { Link } from 'react-router-dom'
import useLangSwitch from 'components/hooks/useLanguageSwicth'

import MyRating from 'components/productUtil/Rating'
import moment from 'moment'
import { CgTimer } from 'react-icons/cg'
import { IoTrashBin, } from 'react-icons/io5'
import { deleteUserReview } from 'store/actions'
import { useDispatch } from 'react-redux'
import { defualtProd } from 'assets/imgs/logos'

const ReviewedItem = ({ item }) => {
    const dispatch = useDispatch()
    const language = useLangSwitch(item?.product?.primaryLang, item?.product?.secondaryLang)

    const removeSpace = language?.name?.replace(/ /g, "-")

    return <div
        className="d-flex align-items-start justify-content-between border-b-4 py-3 bg-lightGray px-3 mb-2 rounded-sm mobileOrderCard"
    >
        <div className="d-flex flex-column align-items-center justify-content-center ">
            <img
                alt="babyday-product-image"
                height={"80px"}
                width={"80px"}
                title="babyday-product-image"
                loading="eager"
                style={{
                    borderRadius: 5
                }}
                className="me-3"
                src={item?.product?.images[0]?.url ?? defualtProd} />
        </div>

        <div className="flex-1">
            <p className="">
                {" "}
                <Link
                    to={`/products/${removeSpace}`}
                    className="text-black"
                >
                    {language?.name}
                </Link>
            </p>
            <div className="rating-star mb-2">
                <MyRating
                    initialRating={item?.rating ?? 0}
                    quiet={true}
                    readonly={true}
                />
            </div>
            <p className="mb-0 text-black">
                {item?.message}
            </p>
            <span>
                <CgTimer />  {moment(item?.createdAt)?.format('MMMM Do, YYYY')}
            </span>
        </div>
        {/* <IoTrashBin style={{ cursor: "pointer" }} onClick={() => dispatch(deleteUserReview(item?._id))} /> */}
    </div>
}

export default ReviewedItem

ReviewedItem.propTypes = {
    item: PropTypes.any
}