import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import {
  GET_USER,
  LOGIN_USER_MOBILE,
  LOGIN_USER_OTP,
  LOGOUT_USER,
  UPDATE_USER_INFO,
} from "./actionTypes"
import {
  logoutuserError,
  loginUserMobileError,
  loginUserMobileSuccess,
  loginUserOtpError,
  loginUserOtpSuccess,
  logoutUserSuccess,
  getUserSuccess,
  getUserError,
  updateUserInfoSuccess,
  updateUserInfoError,
} from "./actions"

import { post, get, ApiPut } from "helpers/api_methods"
import { showMobileNavAction, showToasterAction } from "store/actions"
import { hideLoginPopupAction } from "store/actions"

import { isEmpty } from "lodash"

function loginMobileApi(mobile) {
  return post("/login/mobile", mobile)
}

function registerMobileApi(mobile) {
  return post("/register/mobile", mobile)
}

function loginOtpApi(otp) {
  return post("/verify_otp", otp)
}

function logoutApi() {
  return get("/logout")
}

function getUserApi() {
  return get("/user")
}

function updateUserInfoApi(input) {
  return ApiPut("/user/update", input)
}

function* fetchUser() {
  try {
    const response = yield call(getUserApi)
    yield put(getUserSuccess(response))
  } catch (error) {
    yield put(getUserError(error))
  }
}
function* updateUserInfo({ payload }) {
  try {
    const response = yield call(updateUserInfoApi, payload)
    yield put(updateUserInfoSuccess(response))
    yield put(
      showToasterAction({
        data: { message: "updated", title: "info" },
        type: "default",
      })
    )
  } catch (error) {
    yield put(updateUserInfoError(error))
  }
}
function* loginUserMobile({ payload }) {
  try {
    const response =
      payload.type === "login"
        ? yield call(loginMobileApi, payload.mobile)
        : yield call(registerMobileApi, payload.mobile)

    yield put(loginUserMobileSuccess(response))
    if (response?.otp) {
      yield put(
        showToasterAction({
          data: { title: "OTP", message: response?.otp },
          type: "success",
        })
      )
    } else {
      yield put(
        showToasterAction({
          data: { message: response?.message, title: "info" },
          type: "default",
        })
      )
    }
  } catch (error) {
    yield put(loginUserMobileError(error))
  }
}

function* loginUserOtp({ payload }) {
  try {
    const response = yield call(loginOtpApi, payload)
    yield put(loginUserOtpSuccess(response))
    if (response?.token) {
      yield put(
        showToasterAction({
          data: { message: "Authentication Success" },
          type: "success",
        })
      )
      localStorage.setItem("idealeshope_token", response?.token)
      yield put(hideLoginPopupAction())
      yield put(showMobileNavAction())
    }
  } catch (error) {
    yield put(loginUserOtpError(error))
    yield put(showMobileNavAction())
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    const response = yield call(logoutApi)
    if (isEmpty(response?.token)) {
      yield put(
        showToasterAction({
          data: { title: "", message: "Logout Successfully" },
          type: "success",
        })
      )
      yield put(logoutUserSuccess(response))
      localStorage.removeItem("idealeshope_token")
      history.push("/")
    } else {
      yield put(
        showToasterAction({
          data: { title: "", message: "please try again" },
          type: "warning",
        })
      )
    }
  } catch (error) {
    yield put(logoutuserError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER_MOBILE, loginUserMobile)
  yield takeEvery(LOGIN_USER_OTP, loginUserOtp)
  yield takeEvery(LOGOUT_USER, logoutUser)
  yield takeEvery(GET_USER, fetchUser)
  yield takeEvery(UPDATE_USER_INFO, updateUserInfo)
}

export default authSaga
