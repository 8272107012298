import CategoryTab from "components/ecommerce/categoryTab"
import React from "react"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"
import { useBanner } from "./useBannerData"
import useCloudinary from "components/hooks/useCloudinaryImage"

function ProductG1({ groupDetail }) {
  const product = groupDetail?.products
  const history = useHistory()

  const handleBannerData = useBanner(
    // groupDetail?.singleCollection,
    // groupDetail?.singleCategory,
    groupDetail?.collections,
    groupDetail?.categories,
    groupDetail?.type
  )

  const CollectionLink = handleBannerData[0]?.primaryLang?.title?.replace(
    / /g,
    "-"
  )

  const CategoryLink = handleBannerData[0]?.primaryLang?.name?.replace(
    / /g,
    "-"
  )

  const handleRouteLink = () => {
    switch (groupDetail?.type) {
      case "single collection":
        return history.push(`/collections/${CollectionLink}`)
      case "single category":
        return history.push({
          pathname: "/product",
          query: {
            cat: CategoryLink,
            isProduct: false,
          },
        })

      default:
        break
    }
  }

  return (
    <section className="product-tabs section-padding position-relative">
      <div className="container">
        <div className="col-lg-12">
          <div className="section-title mb-4 mt-4 style-2 wow animate__animated animate__fadeIn ">
            <h3 className="section_head_h3">
              {groupDetail?.primaryLang?.title}
            </h3>
          </div>
          {groupDetail?.images?.length > 0 && (
            <div className="mobileBanner single-hero-slider single-animation-wrap p-5 w-100 mb-4 pb-4 border-radius-15 h-half background_image">
              <img
                src={useCloudinary(
                  groupDetail?.images[0]?.url
                    ? groupDetail?.images[0]?.url
                    : handleBannerData[0]?.bannerImages[0]?.url ?? "",
                  "w_1500"
                )}
                onClick={handleRouteLink}
                className="border-radius-5"
              />
            </div>
          )}

          <CategoryTab products={product} noHead={true} />
        </div>
      </div>
    </section>
  )
}

export default ProductG1

ProductG1.propTypes = {
  groupDetail: PropTypes.object,
}
