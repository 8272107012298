import moment from "moment"
import React from "react"
import { FaStar } from "react-icons/fa"
import { HiBadgeCheck } from "react-icons/hi"
import { useSelector } from "react-redux"
import MyRating from "./Rating"
import { CgTimer } from "react-icons/cg"


function ProductReview() {
  const { productDetail } = useSelector(
    state => ({
      productDetail: state.Products.productDetail,
    })
  )

  const reviews = productDetail?.reviews

  return (
    <div className={"tab-pane fade show active"} id="Reviews">
      <div className="comments-area">
        <div className="row">
          {/* <div className="col-lg-12">
            <h4 className="mb-30">Customer reviews</h4>
            <div className="d-flex mb-30">
              <div className="product-rate d-inline-block mr-15">
                <div
                  className="product-rating"
                  style={{
                    width: "90%",
                  }}
                ></div>
              </div>
              <h6>4.8 out of 5</h6>
            </div>
            <div className="progress">
              <span>5 star</span>
              <div
                className="progress-bar"
                role="progressbar"
                style={{
                  width: " 50%",
                }}
                aria-valuenow="50"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                50%
              </div>
            </div>
            <div className="progress">
              <span>4 star</span>
              <div
                className="progress-bar"
                role="progressbar"
                style={{
                  width: " 25%",
                }}
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                25%
              </div>
            </div>
            <div className="progress">
              <span>3 star</span>
              <div
                className="progress-bar"
                role="progressbar"
                style={{
                  width: " 45%",
                }}
                aria-valuenow="45"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                45%
              </div>
            </div>
            <div className="progress">
              <span>2 star</span>
              <div
                className="progress-bar"
                role="progressbar"
                style={{
                  width: " 65%",
                }}
                aria-valuenow="65"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                65%
              </div>
            </div>
            <div className="progress mb-30">
              <span>1 star</span>
              <div
                className="progress-bar"
                role="progressbar"
                style={{
                  width: " 85%",
                }}
                aria-valuenow="85"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                85%
              </div>
            </div>
            <a href="#" className="font-xs text-muted">
              How are ratings calculated?
            </a>
          </div> */}
          <div className="col-lg-12">
            <h6 className="mb-10" style={{ fontWeight: "400" }}>Customer reviews ({productDetail?.numberOfReviews})</h6>
            <div className="ms-4 mt-3  mb-10 pb-20 " style={{ borderBottom: "1px solid #f2f2f2" }}>
              <div className="d-flex justify-content-start align-items-center">
                <h6 className="me-1" style={{
                  fontSize: 34,
                  fontWeight: 300
                }}>{productDetail?.averageRating}</h6>
                <FaStar size={"1.3rem"} className="primary-color" />

              </div>
              <p className="m-0 p-0">out of 5</p>
              {productDetail?.numberOfReviews?.length >= 5 && <p className="text-green">{productDetail?.numberOfReviews} Verified Buyers</p>}
            </div>

            <div className="comment-list">
              {reviews?.map((item, key) => (
                <div key={key} className="single-comment justify-content-between d-flex">
                  <div className="user justify-content-between align-items-start d-flex">
                    <div className="thumb text-center mt-1">
                      <div className="thumb-star" style={{ borderRadius: 2 }}>
                        {item?.rating} <FaStar />
                      </div>
                    </div>
                    <div className="desc">
                      <MyRating
                        initialRating={item?.rating ?? 0}
                        quiet={true}
                        readonly={true}
                      />
                      <p className="text-black">{item?.message}</p>
                      <div className="d-flex justify-content-between">
                        <div className="d-flex align-items-center">
                          <p className="font-xs mr-30">
                            <span style={{ textTransform: "capitalize", }}>
                              {item?.user?.name}
                            </span>{" "}
                            | {item?.user?.phone?.slice(0, 2)}<span style={{ letterSpacing: 2 }}>****</span>{item?.user?.phone?.slice(7, 10)} <br />
                            <span>
                              <CgTimer />  {moment(item?.createdAt)?.format('MMMM Do, YYYY')}
                            </span>
                          </p>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div>
                    <p className="text-green"><HiBadgeCheck /> Verified Buyer</p>
                  </div>
                </div>
              ))
              }

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductReview
