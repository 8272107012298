import React, { Fragment, useEffect, useRef, useState } from "react"
import download from "assets/imgs/theme/icons/icon-download.svg"
import { GiShoppingBag } from "react-icons/gi"
import PropTypes from "prop-types"
import { useWindowSize } from "react-use"
import { useSelector } from "react-redux"
import { map } from "lodash"
import { useLangSwitchquery } from "components/hooks/useLanguageSwicth"
import usePrice from "components/hooks/usePrice"
import { Link } from "react-router-dom"
import { exportComponentAsJPEG } from "react-component-export-image"
import { MainLogo } from "assets/imgs/logos"
import OrderSuccessMob from "pages/user/order/orderSuccessMob"
import Rating from "react-rating"
import { FaStar } from "react-icons/fa"
import { FaRegStar } from "react-icons/fa"

export const handleOrderItemStatus = status => {
  switch (status) {
    case "Cancelled":
    case "Cancel Processing":
    case "Returned":
    case "Return Processing":
      return true
    default:
      return false
  }
}

function OrderContent({
  isNavbar,
  isFooter,
  isAccount,
  isSuccess,
  setReviewPopup,
}) {
  const { width } = useWindowSize()
  const componentRef = useRef()
  const language = useLangSwitchquery()

  const { order, shipping } = useSelector(state => ({
    order: state.Cart.order,
    shipping: state.SiteSettings.siteSettings.shipping,
  }))

  const [review, setReview] = useState({
    productId: "",
    review: 0,
  })

  const defaultShippingCharge = shipping?.defaultShippingCharge

  const { currencyCode } = usePrice()

  const address = order?.shippingInfo

  const orderItems = order?.orderItems

  // useEffect(() => {
  //   if (!order || !order?.orderId) {
  //     history.push("/")
  //   }
  // }, [order])

  const ratingChanged = (newRating, item) => {
    setReview({
      productId: item?.product,
      review: newRating,
    })
    setReviewPopup({
      status: true,
      item: {
        product: item,
        orderId: order?._id,
        review: newRating,
        setReview: setReview,
      },
    })
  }

  return (
    <div className="row sm:w-100 sm:m-0">
      <div className="col-lg-12 sm:p-0">
        <div className="invoice-inner ">
          <div className="invoice-info" id="invoice_wrapper" ref={componentRef}>
            <div
              className={
                "invoice-header sm:pt-4 sm:pb-1 " +
                `${isNavbar === false ? "d-none" : ""}`
              }
            >
              <div
                className={"row " + `${width < 768 ? "flex-revers-col" : ""}`}
              >
                <div className="col-md-6">
                  <div
                    className={
                      "logo d-flex align-items-center mb-30 " +
                      `${width < 768 ? "d-none" : ""}`
                    }
                  >
                    <a href="#" className="mr-20">
                      <div className="h:40px mb-3" style={{ width: 180 }}>
                        <img
                          src={MainLogo}
                          alt="logo"
                          className="h-100 w-100 fit-contain"
                        />
                      </div>
                    </a>
                  </div>

                  <div className="text">
                    <h6 style={{ color: "#7e7e7e" }} className="mb-10 ">
                      Shipping Address
                    </h6>
                    <strong className="text-black text-size-20 sm:font-size-16">
                      {address?.name
                        ? address?.name
                        : address?.firstName + " " + address?.lastName}
                    </strong>{" "}
                    <p>{address?.contactNumber}</p>
                    <p>{address?.email}</p>
                    <span>{address?.addressLine1}</span>
                    <p>{address?.street}</p>
                    <p>{address?.landmark}</p>
                    <p>{address?.area}</p>
                    <p>{address?.district}</p>
                    <p>{address?.pinCode}</p>
                    {address?.address}
                  </div>
                </div>

                <div className={"col-md-6 text-end sm:text-center sm:mb-30"}>
                  <h2 className="text-brand head">Order Placed</h2>
                  <h6>
                    Order ID:{" "}
                    <span className="text-brand">{order?.orderId}</span>
                  </h6>
                </div>
              </div>
            </div>

            {width >= 480 ? (
              <div className="invoice-center">
                <div className="table-responsive">
                  <table className="table table-striped invoice-table">
                    <thead className="bg-active">
                      <tr>
                        {!isAccount && <th></th>}
                        <th>Item </th>
                        {isAccount ||
                          (isSuccess && <th className="text-start">Status</th>)}
                        <th className="text-center">Price</th>
                        <th className="text-center">Qty</th>
                        <th className="text-right">Total</th>
                      </tr>
                    </thead>

                    <tbody>
                      {map(orderItems, (orderItem, key) => (
                        <Fragment key={key}>
                          <tr>
                            <td>
                              <div className="item-desc-1 d-flex align-items-center ">
                                <img
                                  src={orderItem?.imageUrl}
                                  alt="item"
                                  height={"80px"}
                                  width={"80px"}
                                  style={{
                                    filter:
                                      handleOrderItemStatus(
                                        orderItem?.orderItemStatus
                                      ) && "grayscale(1)",
                                  }}
                                />

                                <div className="mx-2">
                                  <span className="min-w:150px">
                                    {language === "Primary"
                                      ? orderItem?.primaryLang?.name
                                      : orderItem?.secondaryLang?.name}
                                  </span>
                                  <br />
                                  <small>
                                    {orderItem?.varientValue}{" "}
                                    {orderItem?.varientUnit}
                                  </small>
                                </div>
                              </div>
                            </td>
                            <td
                              className={
                                "text-start " +
                                `${
                                  handleOrderItemStatus(
                                    orderItem?.orderItemStatus
                                  )
                                    ? "text-danger"
                                    : "text-info"
                                }`
                              }
                            >
                              {orderItem?.orderItemStatus}
                            </td>
                            <td
                              className="text-center"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              {currencyCode} {orderItem?.price}
                            </td>
                            <td className="text-center">
                              {orderItem?.quantity}
                            </td>

                            <td
                              className="text-right"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              {currencyCode}{" "}
                              {orderItem?.price * orderItem?.quantity}
                            </td>
                          </tr>
                          {orderItem?.isReviewed === true ? (
                            <>
                              <tr>
                                <td colSpan={5}>
                                  <div className="d-flex align-items-end justify-content-between rating-star mb-1">
                                    <div>
                                      <span className="me-3 pt-1">
                                        Rate Product
                                      </span>
                                      <Rating
                                        emptySymbol={<FaRegStar />}
                                        fullSymbol={<FaStar />}
                                        className="text-warning"
                                        initialRating={
                                          orderItem?.review?.rating
                                            ? orderItem?.review?.rating
                                            : 0
                                        }
                                        readonly
                                      />
                                    </div>
                                    <span className="text-green">Reviewed</span>
                                  </div>
                                </td>
                              </tr>
                            </>
                          ) : (
                            <>
                              {orderItem?.orderItemStatus === "Delivered" && (
                                <tr>
                                  <td colSpan={5}>
                                    <div className="d-flex align-items-end rating-star mb-1">
                                      <span className="me-3 pt-1">
                                        Rate Product
                                      </span>
                                      <Rating
                                        emptySymbol={<FaRegStar />}
                                        fullSymbol={<FaStar />}
                                        className="text-warning"
                                        initialRating={
                                          orderItem?.product ===
                                            review.productId && review.review
                                        }
                                        onChange={review =>
                                          ratingChanged(review, orderItem)
                                        }
                                      />
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </>
                          )}
                        </Fragment>
                      ))}

                      <tr>
                        <td colSpan="3" className="text-end f-w-600">
                          Sub Total
                        </td>
                        <td colSpan="2" className="text-right">
                          {" "}
                          {currencyCode} {order?.itemsPrice}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="3" className="text-end f-w-600">
                          COD Charge
                        </td>
                        <td colSpan="2" className="text-right">
                          {currencyCode}
                          {order?.paymentType === "Cash on Delivery" &&
                            order?.codCharge}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="3" className="text-end f-w-600">
                          Shipping
                        </td>
                        <td colSpan="2" className="text-right">
                          {" "}
                          {defaultShippingCharge > order?.shippingPrice && (
                            <del>
                              {" "}
                              {currencyCode} {defaultShippingCharge}
                            </del>
                          )}
                          &nbsp;
                          <span style={{ whiteSpace: "nowrap" }}>
                            {order?.shippingPrice > 0 ? (
                              <span>
                                {currencyCode} {order?.shippingPrice}
                              </span>
                            ) : (
                              <span className="text-green">&nbsp;Free</span>
                            )}
                          </span>
                        </td>
                      </tr>
                      {order?.totalofferDiscount ? (
                        <tr>
                          <td colSpan="3" className="text-end f-w-600">
                            Offer Discount
                          </td>
                          <td colSpan="2" className="text-right text-green">
                            {" "}
                            - {currencyCode} {order?.totalofferDiscount}
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                      {order?.couponDiscount ? (
                        <tr>
                          <td colSpan="3" className="text-end f-w-600">
                            Coupon Discount
                          </td>
                          <td colSpan="2" className="text-right text-green">
                            {" "}
                            - {currencyCode} {order?.couponDiscount}
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                      {order?.totalprimeDiscount ? (
                        <tr>
                          <td colSpan="3" className="text-end f-w-600">
                            Prime Discount
                          </td>
                          <td colSpan="2" className="text-right text-green">
                            {" "}
                            - {currencyCode} {order?.totalprimeDiscount}
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                      {order?.totalDiscount ? (
                        <tr>
                          <td colSpan="3" className="text-end f-w-600">
                            Total Discount
                          </td>
                          <td colSpan="2" className="text-right text-green">
                            {" "}
                            - {currencyCode} {order?.totalDiscount}
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}

                      <tr>
                        <td colSpan="3" className="text-end f-w-600">
                          Grand Total
                        </td>
                        <td colSpan="2" className="text-right f-w-600">
                          {" "}
                          {currencyCode} {order?.totalPrice}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div className="card-body sm:p-1">
                <OrderSuccessMob />
              </div>
            )}

            <div className="invoice-bottom pb-80">
              <div className="row">
                <div className="col-md-6">
                  <h6 className="mb-15 ">Payment Info</h6>
                  <p className="font-sm">
                    <strong>Type: </strong>
                    &nbsp;
                    <span className="text-brand">{order?.paymentType}</span>
                  </p>
                  <p className="font-sm">
                    <strong> Status: </strong>

                    {order?.isPaid ||
                    order?.paymentType === "Online Payment" ? (
                      <span className="text-brand">Paid</span>
                    ) : (
                      <span className="text-warning">Not Paid</span>
                    )}
                  </p>
                </div>

                <div className="col-md-6 text-end sm:pt-4">
                  {width <= 480 ? (
                    <div>
                      <div className="d-flex justify-content-between ml-auto">
                        <p>Sub Total</p>
                        <span>
                          {currencyCode} {order?.itemsPrice}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between ml-auto">
                        <p> Shipping</p>
                        <span>
                          {defaultShippingCharge > order?.shippingPrice && (
                            <del>
                              {currencyCode} {defaultShippingCharge}
                            </del>
                          )}
                          &nbsp;
                          {order?.shippingPrice === 0 ? (
                            <span className="text-green">&nbsp;Free</span>
                          ) : (
                            <span>
                              {currencyCode} {order?.shippingPrice}
                            </span>
                          )}
                        </span>
                      </div>
                      {order?.totalofferDiscount ? (
                        <div className="d-flex justify-content-between ml-auto">
                          <p> Offer Discount</p>
                          <span>
                            {currencyCode} {order?.totalofferDiscount}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                      {order?.couponDiscount ? (
                        <div className="d-flex justify-content-between ml-auto">
                          <p> Coupon Discount</p>
                          <span>
                            {currencyCode} {order?.couponDiscount}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                      {order?.totalprimeDiscount ? (
                        <div className="d-flex justify-content-between ml-auto">
                          <p> Prime Discount</p>
                          <span>
                            {currencyCode} {order?.totalprimeDiscount}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                      {order?.totalDiscount ? (
                        <div className="d-flex justify-content-between ml-auto">
                          <p>Total Discoun</p>
                          <span>
                            {currencyCode} {order?.totalDiscount}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="d-flex justify-content-between ml-auto mt-3">
                        <h6 className="mb-15 sm:mb-0">Grand Total</h6>
                        <span>
                          <h3 className="mt-0 mb-0 text-black totalPrice">
                            {currencyCode} {order?.totalPrice}
                          </h3>
                          <p className="mb-0 text-muted Taxes">
                            taxes included
                          </p>
                        </span>
                      </div>
                    </div>
                  ) : (
                    <>
                      <h6 className="mb-15 sm:mb-0 sm:mt-4">Total Amount</h6>
                      <h3 className="mt-0 mb-0 text-brand totalPrice">
                        {currencyCode} {order?.totalPrice}
                      </h3>
                      <p className="mb-0 text-muted Taxes">taxes included</p>
                    </>
                  )}
                </div>
              </div>
              <div className="row text-center">
                <div className="hr mt-30 mb-30"></div>
                <p className="mb-0 text-muted">
                  <strong>Note:</strong>This is computer generated receipt and
                  does not require physical signature.
                </p>
              </div>
            </div>
          </div>

          {isFooter === false ? (
            <></>
          ) : (
            <div
              className={
                "invoice-btn-section clearfix d-print-none d-flex align-items-center sm:align-items-end justify-content-end sm:flex-col"
              }
            >
              {width > 768 && (
                <Link
                  to={"#!"}
                  id="invoice_download_btn"
                  onClick={() => exportComponentAsJPEG(componentRef)}
                  className="btn btn-lg btn-custom bg-info btn-download hover-up"
                >
                  {" "}
                  <img src={download} alt="" /> Download{" "}
                </Link>
              )}
              <Link
                to={{
                  pathname: "/product",
                  query: {
                    prod: "all",
                  },
                }}
                id="invoice_download_btn"
                className={"btn btn-lg hover-up "}
              >
                <div className="d-flex align-items-center justify-content-center">
                  <GiShoppingBag className="mx-2" /> <span>Shop More</span>{" "}
                </div>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default OrderContent

OrderContent.propTypes = {
  isFooter: PropTypes.bool,
  isNavbar: PropTypes.bool,
  isAccount: PropTypes.bool,
  isSuccess: PropTypes.bool,
  setReviewPopup: PropTypes.func,
}
